import React, { useState } from "react";

import classes from "./Carousel.module.css";

const Carousel = ({ contents }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const nextSlide = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === contents.length - 1 ? 0 : prevIndex + 1
    );
  };
  const prevSlide = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? contents.length - 1 : prevIndex - 1
    );
  };
  return (
    <div className={classes.carousel}>
      <button
        onClick={prevSlide}
        className={`${classes["carousel__btn"]} ${classes["carousel__btn--prev"]}`}
      >
        <svg
          width="15"
          height="22"
          viewBox="0 0 15 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.993166 11.7847C0.486401 11.3843 0.486403 10.6157 0.993167 10.2153L12.6301 1.0215C13.2858 0.503447 14.25 0.97049 14.25 1.80617L14.25 20.1938C14.25 21.0295 13.2858 21.4966 12.6301 20.9785L0.993166 11.7847Z"
            fill="#5FA8D3"
          />
        </svg>
      </button>
      <p className={classes["carousel__text"]}>{contents[activeIndex]}</p>
      <p className={classes.legend}>
        {activeIndex + 1}/{contents.length}
      </p>
      <button
        onClick={nextSlide}
        className={`${classes["carousel__btn"]} ${classes["carousel__btn--next"]}`}
      >
        <svg
          width="15"
          height="22"
          viewBox="0 0 15 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.0068 10.2153C14.5136 10.6157 14.5136 11.3843 14.0068 11.7847L2.36992 20.9785C1.7142 21.4966 0.75 21.0295 0.75 20.1938L0.75 1.80617C0.75 0.970491 1.71421 0.503449 2.36993 1.02151L14.0068 10.2153Z"
            fill="#5FA8D3"
          />
        </svg>
      </button>
    </div>
  );
};
export default Carousel;
