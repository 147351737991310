import React from "react";
import Layout from "../../components/Layout/Layout";
import SessionsList from "../../components/Sessions/SessionsList";

function SessionsPage() {
  return (
    <Layout title={"لیست جلسات"}>
      <SessionsList />
    </Layout>
  );
}

export default SessionsPage;
