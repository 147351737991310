import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import SessionButton from "../../Session/SessionButton/SessionButton";
import ReactPlayer from "react-player";
import { setSessionDone, getSession } from "../../../api/sessionsApi";
import UserContext from "../../../store/user-context";

import classes from "./SessionEleven.module.css";
import poster from "../../../assets/images/poster.jpg";
import video from "../../../assets/videos/session11.mp4";

function SessionEleven(props) {
  const navigate = useNavigate();
  const userCtx = useContext(UserContext);

  const handleClick = async () => {
    const sessionData = await getSession(props.name.substring(7, 9));
    if (!sessionData.done) {
      setSessionDone(
        props.name.substring(7, 9),
        null,
        () => navigate(`/sessions`),
        (val) => userCtx.getSessions(val)
      );
    } else {
      navigate(`/sessions`);
    }
  };

  return (
    <>
      <div className={classes.videoContainer}>
        <ReactPlayer
          url={video}
          light={
            <img src={poster} alt="" style={{ width: "50%", height: "100%" }} />
          }
          width={"100%"}
          height={"100%"}
          controls
        />
      </div>
      <div className={classes.textContainer}>
        <h1>مروری بر کل جلسات</h1>
        <p id="header">متن ویدیو</p>
        <hr />
      </div>
      <div className={classes.videoText}>
        <p>
          خب بچه ها به این جلسه رسیدیم توی این برنامه و حالا میخوایم با هم مرور
          کنیم. این برنامه چه چیزایی بهمون یاد داد
        </p>
        <p>
          اول از همه دیدیم که چطور طرحواره ها، تحمل نکردن پریشانیمون، فاصله
          گرفتن و فرار کردن از فکرا و احساساتمون و گیر کردن توی چرخه ی فکرها
          باعث میشن که ما به بدن خودمون آسیب برسونیم.{" "}
        </p>
        <p>
          بعد اومدیم یاد گرفتیم حالا به جای اینکه به بدنمون آسیب برسونیم تا
          خودمون رو آروم کنیم ، میتونیم از روشهای دیگه ای استفاده بکنیم.
        </p>
        <p>
          اول از همه یاد گرفتیم فکرا و احساسایی که از طرحواره هامون میاد رو فقط
          شناسایی کنیم و ببینیم.
        </p>
        <p>
          بعد اومدیم دیدیم میشه از اونا فاصله گرفت. یعنی اونا مساوی با هویت ما
          نیستن. اونا فقط فکر و احساسن.{" "}
        </p>
        <p>
          بعد یاد گرفتیم چه جایگزین هایی میتونیم برای اونها داشته باشیم. تمرین
          حباب تنفس رو انجام دادیم و یاد گرفتیم چطور میتونیم آرامش و امنیت رو به
          بدنمون بدیم.
        </p>
        <p>
          بعد با تمرین یخ یاد گرفتیم چطور میتونیم پریشانی رو تحمل کنیم بعد رفتیم
          روی حواس پنجگانه تمرکز رو یاد گرفتیم و خودگویی مرحله بعدی بود. اینکه
          یه سری جمله ها یاد بگیریم که در مواقع حساس به شکل سالمی به خودمون بگیم
          که بهمون کمک بکنه.
        </p>
        <p>
          و در نهایت هم یاد گرفتیم چطور به جای اینکه فکرها ما رو درگیر کنن ، ما
          فقط نگاهشون کنیم بدون اینکه بخوایم بگیم خوبن یا بدن. همه‌ این کارها رو
          انجام دادیم تا یاد بگیریم چطور با تجربه های درونیمون که همون فکرا و
          احساس هستن به شکل دیگه ای برخورد بکنیم و خودمون امنیت و آرامش رو تجربه
          بکنیم و جایگزین دیگه ای برای رفتارهای خودزنی داشته باشیم.
        </p>
      </div>
      <SessionButton text={"دیدم"} onClick={handleClick} />
    </>
  );
}

export default SessionEleven;
