import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import SessionButton from "../../Session/SessionButton/SessionButton";

import classes from "./SessionEight.module.css";

import character from "../../../assets/images/characterBody.png";
import listening from "../../../assets/images/listening.png";
import voice from "../../../assets/voices/session8.mp3";
import UserContext from "../../../store/user-context";

const face =
  "data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iMTc1IiBoZWlnaHQ9IjY3IiB2aWV3Qm94PSIwIDAgMTc1IDY3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZWxsaXBzZSBjeD0iMTUyLjYwNiIgY3k9IjQ2LjQ2IiByeD0iMjIiIHJ5PSIyMCIgZmlsbD0iI0ZDQUY5QSIvPgo8ZWxsaXBzZSBjeD0iMjIuNjA2NCIgY3k9IjQ2LjQ2IiByeD0iMjIiIHJ5PSIyMCIgZmlsbD0iI0ZDQUY5QSIvPgo8ZWxsaXBzZSBjeD0iNDEuNjA2NCIgY3k9IjE4LjQ2IiByeD0iMTUiIHJ5PSIxOCIgZmlsbD0iYmxhY2siLz4KPGVsbGlwc2UgY3g9IjEzMi42MDYiIGN5PSIxOC40NiIgcng9IjE1IiByeT0iMTgiIGZpbGw9ImJsYWNrIi8+CjxwYXRoIGQ9Ik03MyA0NUM4NC41IDUwIDkxIDUwLjUgMTAzLjUgNDUiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iNiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+Cjwvc3ZnPgo=";

function SessionEightP2(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const userCtx = useContext(UserContext);

  const handleClick = () => {
    navigate(`/sessions/${props.name}/3`, { state: location.state });
  };

  return (
    <>
      <div className={classes.header}>
        <p>هر صدایی میشنوی رو فقط گوش کن</p>
        <div>
          <img src={listening} alt="" />
        </div>
      </div>
      <AudioPlayer
        autoPlay={false}
        onPlay={(e) => console.log(e)}
        onPause={(e) => console.log(e)}
        onEnded={(e) => console.log(e)}
        src={voice}
        style={{ height: "15%" }}
      />
      <div className={classes.characterMain}>
        <div>
          <img src={character} alt="" />
          <img src={face} alt="" className={classes.faceUp} />
        </div>
        <p>{userCtx.user.username}</p>
      </div>
      <SessionButton text={"بعدی"} onClick={handleClick} />
    </>
  );
}

export default SessionEightP2;
