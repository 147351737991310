import React from "react";
import CircularSlider from "@fseehawer/react-circular-slider";

import happy from "../../assets/images/happy.png";
import love from "../../assets/images/love.png";
import glasson from "../../assets/images/glasson.png";
import lagh from "../../assets/images/lagh.png";
import good from "../../assets/images/good.png";
import normal from "../../assets/images/normal.png";
import notbad from "../../assets/images/notbad.png";
import sad from "../../assets/images/sad.png";
import verysad from "../../assets/images/verysad.png";
import unhappy from "../../assets/images/unhappy.png";

function MoodSelector(props) {
  const handleChange = (value) => {
    switch (value) {
      case 1:
        props.setMood({ name: "9", image: happy, level: 1 });
        break;
      case 2:
        props.setMood({ name: "8", image: love, level: 2 });
        break;
      case 3:
        props.setMood({ name: "7", image: glasson, level: 3 });
        break;
      case 4:
        props.setMood({ name: "6", image: lagh, level: 4 });
        break;
      case 5:
        props.setMood({ name: "5", image: good, level: 5 });
        break;
      case 6:
        props.setMood({ name: "4", image: normal, level: 6 });
        break;
      case 7:
        props.setMood({ name: "3", image: notbad, level: 7 });
        break;
      case 8:
        props.setMood({ name: "2", image: sad, level: 8 });
        break;
      case 9:
        props.setMood({ name: "1", image: verysad, level: 9 });
        break;
      case 10:
        props.setMood({ name: "0", image: unhappy, level: 10 });
        break;
      default:
        break;
    }
  };

  return (
    <CircularSlider
      labelColor="#000000"
      knobColor="balck"
      progressColorFrom="transparent"
      progressColorTo="transparent"
      progressSize={24}
      trackColor="transparent"
      trackSize={24}
      data={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
      min={1}
      max={10}
      dataIndex={JSON.parse(localStorage.getItem("mood")) - 1}
      onChange={(value) => {
        handleChange(value);
      }}
    ></CircularSlider>
  );
}

export default MoodSelector;
