import React, { useState } from "react";

import classes from "./AuthInput.module.css";
import passHide from "../../../assets/images/passHide.svg";
import passShow from "../../../assets/images/passShow.svg";

function InputAuth(props) {
  const [showPass, setShowPass] = useState(false);

  return (
    <div className={classes.inputControlActive}>
      <label htmlFor={props.name}>{props.title}</label>
      <input
        type={"text"}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        className={!showPass & (props.name === "pass") && classes.password}
      />

      {props.name === "pass" && (
        <img
          src={showPass ? passShow : passHide}
          alt=""
          onClick={() => setShowPass((prev) => !prev)}
          className={classes.passEye}
        />
      )}
    </div>
  );
}

export default InputAuth;
