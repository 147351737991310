import React from "react";

import classes from "./HomeModal.module.css";

const HomeModal = ({ isOpen, onClose, children, style }) => {
  if (!isOpen) return null;

  return (
    <div className={classes.backdrop}>
      <div className={classes.modal} style={style}>
        {children}
      </div>
    </div>
  );
};

export default HomeModal;
