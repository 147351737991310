import React from "react";
import Layout from "../../components/Layout/Layout";
import Setting from "../../components/Setting/Setting";

function SettingPage() {
  return (
    <Layout title={"تنظیمات"}>
      <Setting />
    </Layout>
  );
}

export default SettingPage;
