import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { setSessionDone, getSession } from "../../../api/sessionsApi";

import classes from "./SessionFour.module.css";

import character from "../../../assets/images/characterBody.png";
import brain from "../../../assets/images/brain.png";
import heart from "../../../assets/images/heart.png";
import SessionButton from "../../Session/SessionButton/SessionButton";
import UserContext from "../../../store/user-context";

const face =
  "data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iMTE3IiBoZWlnaHQ9IjQ1IiB2aWV3Qm94PSIwIDAgMTE3IDQ1IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZWxsaXBzZSBjeD0iMTAxLjY2NiIgY3k9IjMxLjM1NTMiIHJ4PSIxNC41NzUxIiByeT0iMTMuMjUwMSIgZmlsbD0iI0ZDQUY5QSIvPgo8ZWxsaXBzZSBjeD0iMTUuNTM5OSIgY3k9IjMxLjM1NTMiIHJ4PSIxNC41NzUxIiByeT0iMTMuMjUwMSIgZmlsbD0iI0ZDQUY5QSIvPgo8ZWxsaXBzZSBjeD0iMjguMTI3IiBjeT0iMTIuODA1MiIgcng9IjkuOTM3NTYiIHJ5PSIxMS45MjUxIiBmaWxsPSJibGFjayIvPgo8ZWxsaXBzZSBjeD0iODguNDE1MSIgY3k9IjEyLjgwNTIiIHJ4PSI5LjkzNzU2IiByeT0iMTEuOTI1MSIgZmlsbD0iYmxhY2siLz4KPC9zdmc+Cg==";

function SessionFourP8(props) {
  const navigate = useNavigate();
  const userCtx = useContext(UserContext);

  const handleClick = async () => {
    const sessionData = await getSession(props.name.substring(7, 8));
    if (!sessionData.done) {
      setSessionDone(
        props.name.substring(7, 8),
        null,
        () => navigate(`/sessions`),
        (val) => userCtx.getSessions(val)
      );
    } else {
      navigate(`/sessions`);
    }
  };

  return (
    <>
      <div className={classes.sessionText}>
        <p>عالی بود حالا بزن بریم مرحله بعد</p>
      </div>
      <div
        className={`${classes.characterContainer} ${classes.w60}`}
        style={{ height: "50%" }}
      >
        <div className={classes.circlesNotAnimated}>
          <div className={classes.circleNotAnimated}>
            <img src={brain} alt="" className={classes.brain} />
          </div>
          <div className={classes.circleNotAnimated}>
            <img src={heart} alt="" className={classes.heart} />
          </div>
        </div>
        <div className={classes.characterNotAnimated}>
          <div style={{ position: "relative", height: "100%" }}>
            <img src={character} alt="" style={{ height: "80%" }} />
            <img src={face} alt="" className={classes.face} />
          </div>
          <p style={{ position: "absolute", bottom: "10px" }}>
            {userCtx.user.username}
          </p>
        </div>
      </div>
      <SessionButton text={"مرحله بعدی"} onClick={handleClick} />
    </>
  );
}

export default SessionFourP8;
