import React from "react";

import classes from "./SettingsModal.module.css";

const SettingsModal = ({ isOpen, onClose, children, style }) => {
  if (!isOpen) return null;

  return (
    <div className={classes.backdrop}>
      <div className={classes.modal} style={style}>
        {children}
      </div>
    </div>
  );
};

export default SettingsModal;
