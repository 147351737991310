import React from "react";

import StartPage from "./StartPage/StartPage";
import { useParams } from "react-router-dom";
import AuthLogin from "./AuthLogin/AuthLogin";
import AuthRegister from "./AuthRegister/AuthRegister";

function Auth() {
  const params = useParams();

  let currentPage = <StartPage />;

  switch (params.name) {
    case "login":
      currentPage = <AuthLogin />;
      break;
    case "register":
      currentPage = <AuthRegister />;
      break;
    default:
      break;
  }

  return <>{currentPage}</>;
}

export default Auth;
