import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import classes from "./SessionFour.module.css";

import character from "../../../assets/images/character.png";
import characterR from "../../../assets/images/characterLookR.png";
import characterU from "../../../assets/images/characterLookUp.png";
import brain from "../../../assets/images/brain.png";
import heart from "../../../assets/images/heart.png";
import SessionButton from "../../Session/SessionButton/SessionButton";
import UserContext from "../../../store/user-context";

function SessionFourP6(props) {
  const navigate = useNavigate();
  const [characterImg, setCharacterImg] = useState(character);
  const userCtx = useContext(UserContext);

  useEffect(() => {
    setTimeout(() => {
      setCharacterImg(characterR);
    }, 500);

    setTimeout(() => {
      setCharacterImg(characterU);
    }, 1000);
  }, []);

  const handleClick = () => {
    navigate(`/sessions/${props.name}/7`);
  };
  return (
    <>
      <div className={classes.sessionText}>
        <p>
          می‌خواهیم ببینیم چطور میشه این فکر و احساس رو از خودت جدا کنی و یاد
          بگیری فقط فکر و احساس هستند که داری شون، و می‌تونن ار تو جدا بشن و
          لزوما مساوی با هویت تو نیستند
        </p>
      </div>
      <div
        className={`${classes.characterContainer} ${classes.w60}`}
        style={{ height: "50%" }}
      >
        <div className={classes.circlesNotAnimated}>
          <div
            className={`${classes.circleNotAnimated} ${classes.changeBackground}`}
          >
            <img src={brain} alt="" className={classes.brain} />
          </div>
          <div className={classes.circleNotAnimated}>
            <img src={heart} alt="" className={classes.heart} />
          </div>
        </div>
        <div className={classes.characterNotAnimated}>
          <img
            src={characterImg}
            alt=""
            style={{ transition: "1s ease-in-out", height: "80%" }}
          />
          <p>{userCtx.user.username}</p>
        </div>
      </div>
      <SessionButton text={"بعدی"} onClick={handleClick} />
    </>
  );
}

export default SessionFourP6;
