import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import SessionButton from "../../Session/SessionButton/SessionButton";

import Modal from "../../Ui/Modal";

import classes from "./SessionNine.module.css";

// import message from "../../../assets/images/chat-bubble.png";
import message from "../../../assets/images/message.png";
import character from "../../../assets/images/herocharacter.png";
import Carousel from "../../Ui/Carousel";
import UserContext from "../../../store/user-context";

const messages = [
  "من از پسش برمیام.",
  "بقیه هم مثل من هستن (ممکن هست ایرادهایی هم باشه و این اشکالی نداره)",
  "نهایت تلاش خودم رو می‌کنم و همین ارزشمنده.",
];

function SessionNineP2(props) {
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(true);
  const userCtx = useContext(UserContext);

  function closeModal() {
    setIsOpen(false);
  }

  const handleClick = () => {
    navigate(`/sessions/${props.name}/3`);
  };

  return (
    <>
      <div style={{ position: "relative", height: "45%" }}>
        <img src={message} alt="" className={classes.message} />
        <div className={classes.carousel}>
          <Carousel contents={messages} />
        </div>
      </div>
      <div className={classes.characterMain}>
        <img src={character} alt="" />
        <p>{userCtx.user.username}</p>
      </div>
      <Modal isOpen={modalIsOpen} onClose={closeModal}>
        <p>این جمله‌هارو میتونی با خودت تکرار کنی:)</p>
      </Modal>
      <SessionButton text={"بعدی"} onClick={handleClick} />
    </>
  );
}

export default SessionNineP2;
