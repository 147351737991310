import React from "react";
import { useParams } from "react-router-dom";
import Layout from "../Layout/Layout";
import SessionOne from "../AllSessions/SessionOne/SessionOne";

import SessionTwo from "../AllSessions/SessionTwo/SessionTwo";
import SessionThree from "../AllSessions/SessionThree/SessionThree";
import SessionFour from "../AllSessions/SessionFour/SessionFour";
import SessionFourP2 from "../AllSessions/SessionFour/SessionFourP2";
import SessionFourP3 from "../AllSessions/SessionFour/SessionFourP3";
import SessionFourP4 from "../AllSessions/SessionFour/SessionFourP4";
import SessionFourP5 from "../AllSessions/SessionFour/SessionFourP5";
import SessionFourP6 from "../AllSessions/SessionFour/SessionFourP6";
import SessionFourP7 from "../AllSessions/SessionFour/SessionFourP7";
import SessionFourP8 from "../AllSessions/SessionFour/SessionFourP8";
import SessionSix from "../AllSessions/SessionSix/SessionSix";
import SessionSixP2 from "../AllSessions/SessionSix/SessionSixP2";
import SessionSixP3 from "../AllSessions/SessionSix/SessionSixP3";
import SessionSixP4 from "../AllSessions/SessionSix/SessionSixP4";
import SessionSixP5 from "../AllSessions/SessionSix/SessionSixP5";
import SessionSixP6 from "../AllSessions/SessionSix/SessionSixP6";
import SessionSeven from "../AllSessions/SessionSeven/SessionSeven";
import SessionSevenP2 from "../AllSessions/SessionSeven/SessionSevenP2";
import SessionSevenP3 from "../AllSessions/SessionSeven/SessionSevenP3";
import SessionSevenP4 from "../AllSessions/SessionSeven/SessionSevenP4";
import SessionTen from "../AllSessions/SessionTen/SessionTen";
import SessionTenP2 from "../AllSessions/SessionTen/SessionTenP2";
import SessionTenP3 from "../AllSessions/SessionTen/SessionTenP3";
import SessionTenP4 from "../AllSessions/SessionTen/SessionTenP4";
import SessionTenP5 from "../AllSessions/SessionTen/SessionTenP5";
import SessionTenP6 from "../AllSessions/SessionTen/SessionTenP6";
import SessionTenP7 from "../AllSessions/SessionTen/SessionTenP7";
import SessionFive from "../AllSessions/SessionFive/SessionFive";
import SessionEight from "../AllSessions/SessionEight/SessionEight";
import SessionEightP2 from "../AllSessions/SessionEight/SessionEightP2";
import SessionEightP3 from "../AllSessions/SessionEight/SessionEightP3";
import SessionNine from "../AllSessions/SessionNine/SessionNine";
import SessionNineP2 from "../AllSessions/SessionNine/SessionNineP2";
import SessionNineP3 from "../AllSessions/SessionNine/SessionNineP3";
import SessionThreeP2 from "../AllSessions/SessionThree/SessionThreeP2";
import SessionThreeP3 from "../AllSessions/SessionThree/SessionThreeP3";
import SessionThreeP4 from "../AllSessions/SessionThree/SessionThreeP4";
import SessionThreeP5 from "../AllSessions/SessionThree/SessionThreeP5";
import SessionThreeP6 from "../AllSessions/SessionThree/SessionThreeP6";
import SessionThreeP7 from "../AllSessions/SessionThree/SessionThreeP7";
import SessionThreeP8 from "../AllSessions/SessionThree/SessionThreeP8";
import SessionThreeP9 from "../AllSessions/SessionThree/SessionThreeP9";
import SessionThreeP10 from "../AllSessions/SessionThree/SessionThreeP10";
import SessionEleven from "../AllSessions/SessionEleven/SessionEleven";
import SessionTweleve from "../AllSessions/SessionTweleve/SessionTweleve";
import SessionTweleveP2 from "../AllSessions/SessionTweleve/SessionTweleveP2";
// import SessionTweleveP3 from "../AllSessions/SessionTweleve/SessionTweleveP3";
// import SessionTweleveP4 from "../AllSessions/SessionTweleve/SessionTweleveP4";
// import SessionTweleveP7 from "../AllSessions/SessionTweleve/SessionTweleveP8";
// import SessionTweleveP8 from "../AllSessions/SessionTweleve/SessionTweleveP8";
// import SessionTweleveP6 from "../AllSessions/SessionTweleve/SessionTweleveP6";
// import SessionTweleveP5 from "../AllSessions/SessionTweleve/SessionTweleveP5";

function Session() {
  const params = useParams();
  let isS12First = false;

  let currectSession = <SessionOne name={params.name} />;
  let sessionNum = "۱";

  switch (params.name) {
    case "session2":
      currectSession = <SessionTwo name={params.name} />;
      sessionNum = "۲";

      break;
    case "session3":
      currectSession = <SessionThree name={params.name} />;

      sessionNum = "۳";
      switch (params.page) {
        case "2":
          currectSession = <SessionThreeP2 name={params.name} />;
          break;
        case "3":
          currectSession = <SessionThreeP3 name={params.name} />;
          break;
        case "4":
          currectSession = <SessionThreeP4 name={params.name} />;
          break;
        case "5":
          currectSession = <SessionThreeP5 name={params.name} />;
          break;
        case "6":
          currectSession = <SessionThreeP6 name={params.name} />;
          break;
        case "7":
          currectSession = <SessionThreeP7 name={params.name} />;
          break;
        case "8":
          currectSession = <SessionThreeP8 name={params.name} />;
          break;
        case "9":
          currectSession = <SessionThreeP9 name={params.name} />;
          break;
        case "10":
          currectSession = <SessionThreeP10 name={params.name} />;
          break;
        default:
          break;
      }
      break;
    case "session4":
      currectSession = <SessionFour name={params.name} />;

      sessionNum = "۴";
      switch (params.page) {
        case "2":
          currectSession = <SessionFourP2 name={params.name} />;
          break;
        case "3":
          currectSession = <SessionFourP3 name={params.name} />;
          break;
        case "4":
          currectSession = <SessionFourP4 name={params.name} />;
          break;
        case "5":
          currectSession = <SessionFourP5 name={params.name} />;
          break;
        case "6":
          currectSession = <SessionFourP6 name={params.name} />;
          break;
        case "7":
          currectSession = <SessionFourP7 name={params.name} />;
          break;
        case "8":
          currectSession = <SessionFourP8 name={params.name} />;
          break;
        default:
          break;
      }
      break;
    case "session5":
      sessionNum = "۵";
      currectSession = <SessionFive name={params.name} />;
      break;
    case "session6":
      currectSession = <SessionSix name={params.name} />;

      sessionNum = "۶";
      switch (params.page) {
        case "2":
          currectSession = <SessionSixP2 name={params.name} />;
          break;
        case "3":
          currectSession = <SessionSixP3 name={params.name} />;
          break;
        case "4":
          currectSession = <SessionSixP4 name={params.name} />;
          break;
        case "5":
          currectSession = <SessionSixP5 name={params.name} />;
          break;
        case "6":
          currectSession = <SessionSixP6 name={params.name} />;
          break;

        default:
          break;
      }
      break;
    case "session7":
      currectSession = <SessionSeven name={params.name} />;

      sessionNum = "۷";
      switch (params.page) {
        case "2":
          currectSession = <SessionSevenP2 name={params.name} />;
          break;
        case "3":
          currectSession = <SessionSevenP3 name={params.name} />;
          break;
        case "4":
          currectSession = <SessionSevenP4 name={params.name} />;
          break;

        default:
          break;
      }
      break;
    case "session8":
      currectSession = <SessionEight name={params.name} />;

      sessionNum = "۸";
      switch (params.page) {
        case "2":
          currectSession = <SessionEightP2 name={params.name} />;
          break;
        case "3":
          currectSession = <SessionEightP3 name={params.name} />;
          break;
        default:
          break;
      }
      break;
    case "session9":
      currectSession = <SessionNine name={params.name} />;

      sessionNum = "۹";
      switch (params.page) {
        case "2":
          currectSession = <SessionNineP2 name={params.name} />;
          break;
        case "3":
          currectSession = <SessionNineP3 name={params.name} />;
          break;
        default:
          break;
      }
      break;
    case "session10":
      currectSession = <SessionTen name={params.name} />;

      sessionNum = "۱۰";
      switch (params.page) {
        case "2":
          currectSession = <SessionTenP2 name={params.name} />;
          break;
        case "3":
          currectSession = <SessionTenP3 name={params.name} />;
          break;
        case "4":
          currectSession = <SessionTenP4 name={params.name} />;
          break;
        case "5":
          currectSession = <SessionTenP5 name={params.name} />;
          break;
        case "6":
          currectSession = <SessionTenP6 name={params.name} />;
          break;
        case "7":
          currectSession = <SessionTenP7 name={params.name} />;
          break;
        default:
          break;
      }
      break;
    case "session11":
      currectSession = <SessionEleven name={params.name} />;

      sessionNum = "۱۱";
      break;
    case "session12":
      currectSession = <SessionTweleve name={params.name} />;
      isS12First = true;
      sessionNum = "۱۲";
      switch (params.page) {
        case "2":
          currectSession = <SessionTweleveP2 name={params.name} />;
          isS12First = true;
          break;
        // case "3":
        //   currectSession = <SessionTweleveP3 name={params.name} />;
        //   isS12First = false;
        //   break;
        // case "4":
        //   currectSession = <SessionTweleveP4 name={params.name} />;
        //   isS12First = false;
        //   break;
        // case "5":
        //   currectSession = <SessionTweleveP5 name={params.name} />;
        //   isS12First = false;
        //   break;
        // case "6":
        //   currectSession = <SessionTweleveP6 name={params.name} />;
        //   isS12First = false;
        //   break;
        // case "7":
        //   currectSession = <SessionTweleveP7 name={params.name} />;
        //   isS12First = false;
        //   break;
        // case "8":
        //   currectSession = <SessionTweleveP8 name={params.name} />;
        //   isS12First = false;
        //   break;
        default:
          break;
      }
      break;
    default:
      break;
  }

  return (
    <Layout
      title={`جلسه ${sessionNum}`}
      isSessionPage={true}
      name={params.name}
      isSession12First={isS12First}
    >
      {currectSession}
    </Layout>
  );
}

export default Session;
