import React from "react";

import classes from "./Modal.module.css";

import modalIcon from "../../assets/images/modalIcon.svg";

const Modal = ({ isOpen, onClose, children, buttonContent }) => {
  if (!isOpen) return null;

  return (
    <div onClick={onClose} className={classes.backdrop}>
      <div className={classes.modal}>
        <img src={modalIcon} alt="" className={classes.icon} />
        {children}
        <button onClick={onClose} className={classes.button}>
          {buttonContent ? buttonContent : "دیدن جمله ها"}
        </button>
      </div>
    </div>
  );
};

export default Modal;
