import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SessionButton from "../../Session/SessionButton/SessionButton";
import ReactPlayer from "react-player";

import classes from "./SessionTweleve.module.css";
import poster from "../../../assets/images/poster.jpg";
import video from "../../../assets/videos/session12.mp4";
import Modal from "../../Ui/Modal";

function SessionTweleve(props) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleClick = async () => {
    navigate(`/sessions/${props.name}/2`);
  };

  return (
    <>
      <div className={classes.videoContainer}>
        <ReactPlayer
          url={video}
          light={
            <img src={poster} alt="" style={{ width: "50%", height: "100%" }} />
          }
          width={"100%"}
          height={"100%"}
          controls={true}
        />
      </div>
      <div className={classes.textContainer}>
        <h1>حرف آخر :)</h1>
        <p id="header">متن ویدیو</p>
        <hr />
      </div>
      <div className={classes.videoText}>
        <p>
          خب بچه ها رسیدیم به اخرین مرحله. اولا تبریک بابت اینکه تا اینجا اومدین
          جلو و بعد یه نکته در مورد زندگی اینکه همه ی این چیزایی که یاد گرفتیم
          تو این برنامه بچه ها با تمرین و تکراره که بیشتر برامون میمونه مثل هر
          مهارت دیگه ای مثلا اگر دوچرخه سواری کرده باشین حتما دقت کردین که دفعه
          های اول چقدر ممکنه سخت باشه ولی به مرور خیلی راحت تر میشه و هر چه
          بیشتر تمرین کنین توش مهارتشون بیشتر میشه. در مورد این تمرینها هم
          همینطوره. هرچه بیشتر اونها رو توی موقعیتهای واقعی زندگی تمرین کنیم
          بیشتر جا میفتن و توشون مهارتمون بیشتر میشه.
        </p>
        <p>
          اما میخوام ازتون تشکر کنم که اینقدر خوب و عالی تا این مرحله پیش اومدین
          و مرحله به مرحله همراه شدین و تمرینها رو انجام دادین. امیدوارم این
          برنامه رو هم بتونین تو زندگیتون همینقدر خوب پیش ببرین.
        </p>
        <p> مرسی و موفق باشین بچه ها</p>
      </div>
      <SessionButton text={"بعدی"} onClick={handleClick} />
      <Modal isOpen={isOpen} onClose={closeModal} buttonContent={"حله!"}>
        <p>
          حالا که تا اینجا اومدی و مراحل رو تموم کردی، لطفا روی لینک زیر برو و
          به سوالایی که هست جواب بده و در نهایت با ارسال جوابها کارت هدیه‌ات رو
          بگیر
        </p>
      </Modal>
    </>
  );
}

export default SessionTweleve;
