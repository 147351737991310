import React from "react";
import { useNavigate } from "react-router-dom";

import classes from "./Card.module.css";

import question from "../../assets/images/cardQuestionMark.png";

function Card(props) {
  const navigate = useNavigate();
  return (
    <div
      className={props.expand ? classes.expnadedCard : classes.card}
      {...props}
    >
      <img className={classes.badge} src={question} alt="qusetion" />
      <p style={{ whiteSpace: "pre-line" }}>{props.text}</p>
      {props.expand && (
        <div className={classes.back} onClick={() => navigate(-1)}>
          <p>قبلی</p>
          <svg
            width="11"
            height="19"
            viewBox="0 0 11 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.77749 2.33773C10.0742 2.03171 10.0742 1.53554 9.77749 1.22952C9.48082 0.923494 8.99981 0.923494 8.70314 1.22952L1.22251 8.94589C0.925831 9.25192 0.925831 9.74808 1.22251 10.0541L8.70314 17.7705C8.99981 18.0765 9.48082 18.0765 9.77749 17.7705C10.0742 17.4645 10.0742 16.9683 9.77749 16.6623L2.83404 9.5L9.77749 2.33773Z"
              fill="#666666"
              stroke="#666666"
            />
          </svg>
        </div>
      )}
    </div>
  );
}

export default Card;
