import React, { useContext, useState } from "react";
import InputSettings from "./Input/InputSettings";
import SettingsModal from "./Modal/SettingsModal";
import { OrbitProgress } from "react-loading-indicators";

import classes from "./Setting.module.css";
import character from "../../assets/images/logoutCharacter.png";
import characterDoneEidt from "../../assets/images/editProfileCharacter.svg";
import SettingButton from "./SettingsButton/SettingButton";
import UserContext from "../../store/user-context";
import { useNavigate } from "react-router-dom";
import ErrorMessage from "../Ui/ErrorMessage";

function Setting() {
  const userCtx = useContext(UserContext);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [doneEidtProfModal, setDoneEidtProfModal] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  function closeLogoutModal() {
    setIsOpen(false);
  }
  function editModalClose() {
    setEditModalIsOpen(false);
  }
  function doneEidtProfModalClose() {
    setDoneEidtProfModal(false);
    setError("");
    setNewPassword("");
    setOldPassword("");
  }

  return (
    <div className={classes.container}>
      <InputSettings
        name={"username"}
        title={"نام کاربری"}
        value={userCtx.user.username}
        active={false}
      />
      <InputSettings
        name={"pass"}
        title={"رمز عبور"}
        value={"test"}
        active={false}
      />

      <div className={classes.w85} style={{ marginTop: "5%" }}>
        <SettingButton
          name={"primary"}
          onClick={() => setEditModalIsOpen(true)}
        >
          ویرایش اطلاعات
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.37382 20H0.887906C0.399735 20 0 19.5998 0 19.1111V16.6225C0 16.3777 0.0884444 16.1555 0.266194 16.0001L11.0321 5.2226C11.3876 4.86669 11.9425 4.86669 12.2971 5.2226L14.7831 7.71121C15.1386 8.06711 15.1386 8.62265 14.7831 8.97767L3.99544 19.7334C3.84023 19.9114 3.5958 20 3.37382 20ZM16.6041 6.75541C16.3595 6.75541 16.1376 6.66687 15.9824 6.48892L13.4964 4.00031C13.3187 3.82236 13.2302 3.60014 13.2302 3.37792C13.2302 3.15571 13.3187 2.91091 13.4964 2.75554L15.9824 0.266927C16.3379 -0.0889758 16.8928 -0.0889758 17.2474 0.266927L19.7334 2.75554C20.0889 3.11144 20.0889 3.66699 19.7334 4.022L17.2257 6.48906C17.0696 6.64444 16.8477 6.75541 16.6041 6.75541Z"
              fill="white"
            />
          </svg>
        </SettingButton>
      </div>
      <div className={classes.w85}>
        <SettingButton name="secondary" onClick={() => setIsOpen(true)}>
          خروج از حساب
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.332 22.668V18.668H12V13.332H21.332V9.33197L28 16L21.332 22.668ZM18.668 2.66797C19.0179 2.66771 19.3645 2.73643 19.6878 2.87022C20.0111 3.004 20.3049 3.20022 20.5523 3.44765C20.7997 3.69508 20.996 3.98886 21.1298 4.31219C21.2635 4.63552 21.3323 4.98205 21.332 5.33197V7.99997H18.668V5.33197H6.668V26.668H18.668V24H21.332V26.668C21.3323 27.0179 21.2635 27.3644 21.1298 27.6877C20.996 28.0111 20.7997 28.3049 20.5523 28.5523C20.3049 28.7997 20.0111 28.9959 19.6878 29.1297C19.3645 29.2635 19.0179 29.3322 18.668 29.332H6.668C6.3179 29.3322 5.97117 29.2635 5.64761 29.1298C5.32406 28.9961 5.03001 28.7999 4.78227 28.5525C4.53452 28.3052 4.33792 28.0114 4.2037 27.6881C4.06948 27.3647 4.00026 27.0181 4 26.668V5.33197C4.00026 4.98187 4.06948 4.63524 4.2037 4.31189C4.33792 3.98853 4.53452 3.69478 4.78227 3.44741C5.03001 3.20003 5.32406 3.00388 5.64761 2.87014C5.97117 2.7364 6.3179 2.66771 6.668 2.66797H18.668Z"
              fill="#CF0000"
            />
          </svg>
        </SettingButton>
      </div>
      <SettingsModal isOpen={modalIsOpen} onClose={closeLogoutModal}>
        <img src={character} alt="" />
        <p className={classes.modalText}>مطمئنی میخوای از حسابت خارج شی؟</p>
        <div className={classes.logoutModalContainer}>
          <div className={classes.w45}>
            <SettingButton
              name={"primary"}
              style={{ fontWeight: "700" }}
              onClick={closeLogoutModal}
            >
              نه، میمونم!
            </SettingButton>
          </div>
          <button
            className={`${classes.logoutButton} ${classes.w45}`}
            onClick={() => userCtx.logUserOut(() => navigate("/"))}
          >
            آره
          </button>
        </div>
      </SettingsModal>
      <SettingsModal
        isOpen={editModalIsOpen}
        onClose={editModalClose}
        style={{ height: "62%" }}
      >
        <h1 className={classes.editTitle}>ویرایش اطلاعات حساب</h1>
        <InputSettings
          name={"pass"}
          title={"رمز عبور قبلی"}
          value={oldPassword}
          active={true}
          onChange={setOldPassword}
        />
        {error && (
          <ErrorMessage style={{ marginTop: "-8%", marginBottom: "-8%" }}>
            {error}
          </ErrorMessage>
        )}
        <InputSettings
          name={"pass"}
          title={"رمز عبور جدید"}
          value={newPassword}
          active={true}
          onChange={setNewPassword}
        />
        <div className={classes.w85}>
          <SettingButton
            name={"primary"}
            style={{ fontWeight: "700" }}
            onClick={() => {
              userCtx.changePass(
                oldPassword,
                newPassword,
                () => {
                  editModalClose();
                  setDoneEidtProfModal(true);
                },
                (val) => setError(val),
                (val) => setLoading(val)
              );
            }}
          >
            {loading ? (
              <OrbitProgress
                color="#ffffff"
                size="small"
                text=""
                textColor=""
                style={{ fontSize: "6px" }}
              />
            ) : (
              "تایید"
            )}
          </SettingButton>
        </div>
        <div className={classes.w85}>
          <SettingButton
            name={"secondary"}
            style={{ fontWeight: "700" }}
            onClick={editModalClose}
          >
            بستن
          </SettingButton>
        </div>
      </SettingsModal>

      <SettingsModal
        onClose={doneEidtProfModalClose}
        isOpen={doneEidtProfModal}
      >
        <img src={characterDoneEidt} alt="" />
        <p className={classes.modalText}>
          اطلاعات حسابت با موفقیت تغییر پیدا کرد!
        </p>
        <div className={classes.w85}>
          <SettingButton
            name={"primary"}
            style={{ fontWeight: "700" }}
            onClick={doneEidtProfModalClose}
          >
            متوجه شدم
          </SettingButton>
        </div>
      </SettingsModal>
    </div>
  );
}

export default Setting;
