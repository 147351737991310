import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SessionButton from "../../Session/SessionButton/SessionButton";

import classes from "./SessionTen.module.css";

import character from "../../../assets/images/characterBody.png";
import room from "../../../assets/images/room.png";
import doors from "../../../assets/images/doorsLO.png";
import message from "../../../assets/images/messageSmall.png";
import { getSession } from "../../../api/sessionsApi";
import HomeModal from "../../Home/modal/HomeModal";

const face =
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCA1MCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGVsbGlwc2UgY3g9IjQyLjUyMTkiIGN5PSIxMS45MjkiIHJ4PSI2LjUyMTg1IiByeT0iNS45Mjg5NSIgZmlsbD0iI0ZDQUY5QSIvPgo8ZWxsaXBzZSBjeD0iNi41MjE4NSIgY3k9IjExLjkyOSIgcng9IjYuNTIxODUiIHJ5PSI1LjkyODk1IiBmaWxsPSIjRkNBRjlBIi8+CjxlbGxpcHNlIGN4PSIxMS4zNzkzIiBjeT0iNS41OTUzNCIgcng9IjQuNDQ2NzIiIHJ5PSI1LjMzNjA2IiBmaWxsPSJibGFjayIvPgo8ZWxsaXBzZSBjeD0iMzguMzU1OSIgY3k9IjUuNTk1MzQiIHJ4PSI0LjQ0NjcyIiByeT0iNS4zMzYwNiIgZmlsbD0iYmxhY2siLz4KPHBhdGggZD0iTTIyIDE1QzI1LjQwOTEgMTYuNDgyMiAyNy4zMzYxIDE2LjYzMDUgMzEuMDQxNyAxNSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==";

function SessionTenP4(props) {
  const [thought, setThought] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const closeModalhandler = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    const getData = async () => {
      const res = await getSession(props.name.substring(7, 9));
      if (res.data !== null) {
        setThought(res.data);
      }
    };
    getData();
  }, [props.name]);

  const handleClick = () => {
    if (thought !== "") {
      navigate(`/sessions/${props.name}/5`, {
        state: {
          thought: thought,
        },
      });
    } else {
      setModalOpen(true);
    }
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <div className={classes.character}>
          <div style={{ position: "relative" }}>
            <img src={room} alt="" className={classes.room} />
            <div className={classes.doorsContainer}>
              <div
                style={{ position: "relative", width: "100%", height: "100%" }}
              >
                <HomeModal onClose={closeModalhandler} isOpen={modalOpen}>
                  <p className={classes.modalText}> فکراتو داخل بنویس :)</p>
                  <button
                    className={classes.modalButton}
                    onClick={closeModalhandler}
                  >
                    بستن
                  </button>
                </HomeModal>
                <img src={doors} alt="" className={classes.doorsOpen} />
                <div className={classes.messageContainerBig}>
                  <div
                    style={{
                      position: "relative",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <img
                      src={message}
                      alt=""
                      style={{ height: "50%", width: "100%" }}
                    />
                    <textarea
                      type="text"
                      name="thoughts"
                      id=""
                      placeholder="اینجا بنویس"
                      value={thought}
                      onChange={(e) => setThought(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.characterImg}>
            <div style={{ position: "relative", height: "100%" }}>
              <img
                src={character}
                alt=""
                style={{ height: "100%", width: "100%" }}
              />
              <img src={face} alt="" className={classes.faceNotAnimated} />
            </div>
          </div>
        </div>
      </div>
      <SessionButton text={"بعدی"} onClick={handleClick} />
    </>
  );
}

export default SessionTenP4;
