import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import classes from "./SessionFour.module.css";

import character from "../../../assets/images/holecharacterHB.png";
import SessionButton from "../../Session/SessionButton/SessionButton";
import UserContext from "../../../store/user-context";

function SessionFourP3(props) {
  const navigate = useNavigate();
  const userCtx = useContext(UserContext);

  const handleClick = () => {
    navigate(`/sessions/${props.name}/4`);
  };

  return (
    <>
      <div className={classes.sessionText}>
        <p>یک فکر و احساس از مرحله قبل که خیلی اذیتت کرده رو یادت بیار</p>
      </div>
      <div className={classes.characterMain}>
        <img src={character} alt="" />
        <p>{userCtx.user.username}</p>
      </div>
      <SessionButton text={"بعدی"} onClick={handleClick} />
    </>
  );
}

export default SessionFourP3;
