import React, { useContext, useState } from "react";
import Card from "../../Ui/Card";
import { useNavigate } from "react-router-dom";
import SessionButton from "../../Session/SessionButton/SessionButton";

import classes from "./SessionNine.module.css";

import character from "../../../assets/images/herocharacter.png";
import UserContext from "../../../store/user-context";
import Modal from "../../Ui/Modal";

function SessionNine(props) {
  const navigate = useNavigate();
  const userCtx = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(true);

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleClick = () => {
    navigate(`/sessions/${props.name}/2`);
  };

  return (
    <>
      <div style={{ height: "44%" }}>
        <Card
          text={
            "این قهرمان من هست و می‌خواد چند جمله مهم بهت بگه. تو هم می‌تونی مشابهش رو برای خودت بسازی."
          }
        />
      </div>
      <div className={classes.characterMain}>
        <img src={character} alt="" />
        <p>{userCtx.user.username}</p>
      </div>
      <SessionButton text={"بزن بریم!"} onClick={handleClick} />
      <Modal isOpen={isOpen} onClose={closeModal} buttonContent={"بزن بریم"}>
        <p>
          و آخرین جایگزین برای فکر و احساس ما می‌تونه این باشه که یاد بگیریم
          جمله‌های جدیدی به خودمون بگیم و اونها رو تو موقعیت‌های واقعی تمرین
          کنیم. تو این جلسه این رو می‌بینیم.
        </p>
      </Modal>
    </>
  );
}

export default SessionNine;
