import React, { useContext } from "react";

import classes from "./SessionsList.module.css";
import SessionItem from "./SessionItem";

import session1 from "../../assets/images/session1.png";
import session2 from "../../assets/images/session2.png";
import session3 from "../../assets/images/session3.png";
import session4 from "../../assets/images/session4.png";
import session5 from "../../assets/images/session5.png";
import session6 from "../../assets/images/session6.png";
import session7 from "../../assets/images/session7.png";
import session8 from "../../assets/images/session8.png";
import session9 from "../../assets/images/session9.png";
import session10 from "../../assets/images/session10.png";
import session11 from "../../assets/images/session11.png";
import session12 from "../../assets/images/session12.png";
import UserContext from "../../store/user-context";

const sessions = [
  { title: "جلسه ۱", image: session1, slug: "session1" },
  { title: "جلسه ۲", image: session2, slug: "session2" },
  { title: "جلسه ۳", image: session3, slug: "session3" },
  { title: "جلسه ۴", image: session4, slug: "session4" },
  { title: "جلسه ۵", image: session5, slug: "session5" },
  { title: "جلسه ۶", image: session6, slug: "session6" },
  { title: "جلسه ۷", image: session7, slug: "session7" },
  { title: "جلسه ۸", image: session8, slug: "session8" },
  { title: "جلسه ۹", image: session9, slug: "session9" },
  { title: "جلسه ۱۰", image: session10, slug: "session10" },
  { title: "جلسه ۱۱", image: session11, slug: "session11" },
  { title: "جلسه ۱۲", image: session12, slug: "session12" },
];

function SessionsList() {
  const userCtx = useContext(UserContext);
  return (
    <div className={classes.sessions}>
      {sessions.map((session) => (
        <SessionItem
          key={session.title}
          title={session.title}
          image={session.image}
          slug={session.slug}
          done={userCtx.sessions[session.slug]}
        />
      ))}
    </div>
  );
}

export default SessionsList;
