import React from "react";

import classes from "./SettingButton.module.css";

function SettingButton(props) {
  return (
    <button
      className={
        props.name === "primary" ? classes.blueButton : classes.redButton
      }
      onClick={props.onClick}
      {...props}
    >
      {props.children}
    </button>
  );
}

export default SettingButton;
