import React, { useContext, useEffect, useState } from "react";
import Card from "../../Ui/Card";
import { useNavigate } from "react-router-dom";
import SessionButton from "../../Session/SessionButton/SessionButton";

import classes from "./SessionEight.module.css";

import character from "../../../assets/images/characterNormal.png";
import { getSession } from "../../../api/sessionsApi";
import UserContext from "../../../store/user-context";
import Modal from "../../Ui/Modal";

function SessionEight(props) {
  const [hearedthings, setHearedThings] = useState("");
  const navigate = useNavigate();
  const userCtx = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(true);

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const getData = async () => {
      const res = await getSession(props.name.substring(7, 8));
      if (res.data !== null) {
        setHearedThings(res.data);
      }
    };
    getData();
  }, [props.name]);

  const handleClick = () => {
    navigate(`/sessions/${props.name}/2`, {
      state: { hearedThings: hearedthings },
    });
  };

  return (
    <>
      <div style={{ height: "40%" }}>
        <Card
          text={
            "قرار هست بیایم روی حس شنوایی تمرکز کنیم. هر صدایی میشنوی رو فقط گوش کن"
          }
        />
      </div>
      <div className={classes.characterMain}>
        <img src={character} alt="" />
        <p>{userCtx.user.username}</p>
      </div>
      <SessionButton text={"بزن بریم!"} onClick={handleClick} />
      <Modal isOpen={isOpen} onClose={closeModal} buttonContent={"بزن بریم"}>
        <p>
          همچنان برای اینکه یاد بگیریم توجهمون رو از روی فکر و احساسمون برداریم
          به حواس پنجگانه توجه می‌کنیم و این دفعه حس شنوایی.
        </p>
      </Modal>
    </>
  );
}

export default SessionEight;
