import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SessionButton from "../../Session/SessionButton/SessionButton";
import { setSessionDone } from "../../../api/sessionsApi";

import classes from "./SessionThree.module.css";

import character from "../../../assets/images/holecharacterHB.png";
import UserContext from "../../../store/user-context";

function SessionThreeP10(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const userCtx = useContext(UserContext);

  const handleClick = async () => {
    setSessionDone(
      props.name.substring(7, 8),
      JSON.stringify(location.state),
      () => navigate(`/sessions`),
      (val) => userCtx.getSessions(val)
    );
  };

  return (
    <>
      <div className={classes.sessionText}>
        <p>عالی انجامش دادی! بزن بریم برای بقیه ی جلسات:)</p>
      </div>
      <div className={classes.characterMain}>
        <img src={character} alt="" />
        <p>{userCtx.user.username}</p>
      </div>
      <SessionButton text={"بزن بریم!"} onClick={handleClick} />
    </>
  );
}

export default SessionThreeP10;
