import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import SessionButton from "../../Session/SessionButton/SessionButton";
import SessionChat from "../../Session/SessionChat/SessionChat";
import { setSessionDone, getSession } from "../../../api/sessionsApi";

import classes from "./SessionTwo.module.css";
import sessionTwoPic from "../../../assets/images/sessionTwoPic.png";
import UserContext from "../../../store/user-context";
import voice from "../../../assets/voices/session2.m4a";
import Modal from "../../Ui/Modal";

function SessionTwo(props) {
  const navigate = useNavigate();
  const userCtx = useContext(UserContext);
  const [sendMessage1, setSendMessage1] = useState(false);
  const [sendMessage2, setSendMessage2] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const closeModal = () => {
    setIsOpen(false);
    setTimeout(() => {
      setSendMessage1(true);
    }, 1200);
    setTimeout(() => {
      setSendMessage2(true);
    }, 300);
  };

  const handleClick = async () => {
    const sessionData = await getSession(props.name.substring(7, 8));
    if (!sessionData.done) {
      setSessionDone(
        props.name.substring(7, 8),
        null,
        () => navigate(`/sessions`),
        (val) => userCtx.getSessions(val)
      );
    } else {
      navigate(`/sessions`);
    }
  };

  return (
    <>
      <div className={classes.chatContainer}>
        {sendMessage1
          ? !isOpen && (
              <SessionChat message={"بریم با هم ویس این جلسه رو گوش کنیم"} />
            )
          : !isOpen && <SessionChat message={"در حال تایپ..."} />}
        {sendMessage1
          ? sendMessage2
            ? !isOpen && (
                <SessionChat
                  message={
                    "بررسی عمیق مولفه‌های پروتکل - از طرح‌واره‌ها تا رفتارهای خودآسیب‌رسانی"
                  }
                  img={sessionTwoPic}
                  voice={voice}
                />
              )
            : !isOpen && <SessionChat message={"در حال تایپ..."} />
          : ""}
      </div>
      <SessionButton text={"بعدی"} onClick={handleClick} />
      <Modal isOpen={isOpen} onClose={closeModal} buttonContent={"بزن بریم"}>
        <p>
          اینجا با هم می‌ببینیم چه عواملی و چطوری باعث می‌شن تو این رفتارها رو
          انجام بدی.
        </p>
      </Modal>
    </>
  );
}

export default SessionTwo;
