import React from "react";
import { useNavigate } from "react-router-dom";

import classes from "./Header.module.css";

import backArrow from "../../assets/images/back-arrow.png";

function Header(props) {
  const navigate = useNavigate();

  return (
    <div className={classes.header}>
      <h1>{props.title}</h1>
      {props.hasBack && (
        <img src={backArrow} alt="" onClick={() => navigate('/sessions')} />
      )}
    </div>
  );
}

export default Header;
