import React, { useContext } from "react";
import Card from "../../Ui/Card";
import { useNavigate } from "react-router-dom";
import SessionButton from "../../Session/SessionButton/SessionButton";

import classes from "./SessionFour.module.css";

import character from "../../../assets/images/character.png";
import UserContext from "../../../store/user-context";

function SessionFour(props) {
  const navigate = useNavigate();
  const userCtx = useContext(UserContext);

  const handleClick = () => {
    navigate(`/sessions/${props.name}/2`);
  };

  return (
    <>
      <div style={{ height: "50%" }}>
        <Card
          text={
            "خب حالا قراره بریم سراغ اینکه با این فکرها و احساسات کاری بکنیم و یاد بگیریم طور دیگه ای باهاشون برخورد کنیم"
          }
        />
      </div>
      <div className={classes.characterMain}>
        <img src={character} alt="" />
        <p>{userCtx.user.username}</p>
      </div>
      <SessionButton text={"بزن بریم!"} onClick={handleClick} />
    </>
  );
}

export default SessionFour;
