import React from "react";
import { useNavigate } from "react-router-dom";
import SessionButton from "../../Session/SessionButton/SessionButton";

import classes from "./SessionTen.module.css";

import character from "../../../assets/images/characterNormal.png";
import room from "../../../assets/images/room.png";
import doors from "../../../assets/images/doorsLO.png";

function SessionTenP2(props) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/sessions/${props.name}/3`);
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <div className={classes.character}>
          <div style={{ position: "relative" }}>
            <img src={room} alt="" className={classes.room} />
            <img src={doors} alt="" className={classes.doors} />
          </div>

          <img src={character} alt="" className={classes.characterImg} />
        </div>
      </div>
      <SessionButton text={"بعدی"} onClick={handleClick} />
    </>
  );
}

export default SessionTenP2;
