import { Navigate } from "react-router-dom";

const ProtectedRouts = ({ children }) => {
  const token = localStorage.getItem("token");
  const parsedToken = JSON.parse(token);

  if (parsedToken !== null) {
    return children;
  } else {
    return <Navigate to={"/auth/"} replace />;
  }
};

export default ProtectedRouts;
