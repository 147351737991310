import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import SessionButton from "../../Session/SessionButton/SessionButton";
import SessionChat from "../../Session/SessionChat/SessionChat";

import classes from "./SessionOne.module.css";
import sessionOnePic from "../../../assets/images/sessionOnePic.png";
import { getSession, setSessionDone } from "../../../api/sessionsApi";
import UserContext from "../../../store/user-context";
import voice from "../../../assets/voices/session1.m4a";
import Modal from "../../Ui/Modal";

function SessionOne(props) {
  const navigate = useNavigate();
  const userCtx = useContext(UserContext);
  const [sendMessage1, setSendMessage1] = useState(false);
  const [sendMessage2, setSendMessage2] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const closeModal = () => {
    setIsOpen(false);
    setTimeout(() => {
      setSendMessage1(true);
    }, 1200);
    setTimeout(() => {
      setSendMessage2(true);
    }, 3000);
  };

  const handleClick = async () => {
    const sessionData = await getSession(props.name.substring(7, 8));
    if (!sessionData.done) {
      setSessionDone(
        props.name.substring(7, 8),
        null,
        () => navigate(`/sessions`),
        (val) => userCtx.getSessions(val)
      );
    } else {
      navigate(`/sessions`);
    }
  };

  return (
    <>
      <div className={classes.chatContainer}>
        {sendMessage1
          ? !isOpen && (
              <SessionChat message={"بریم با هم ویس این جلسه رو گوش کنیم"} />
            )
          : !isOpen && <SessionChat message={"در حال تایپ..."} />}
        {sendMessage1
          ? sendMessage2
            ? !isOpen && (
                <SessionChat
                  message={"بررسی رفتارهای خودجرحی"}
                  img={sessionOnePic}
                  voice={voice}
                />
              )
            : !isOpen && <SessionChat message={"در حال تایپ..."} />
          : ""}
      </div>
      <SessionButton text={"بعدی"} onClick={handleClick} />
      <Modal isOpen={isOpen} onClose={closeModal} buttonContent={"بزن بریم"}>
        <p>
          تو این جلسه می‌خواهیم یاد بگیریم رفتارهای خودجرحی چه رفتارهایی هستند.
        </p>
      </Modal>
    </>
  );
}

export default SessionOne;
