import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import classes from "./SessionFour.module.css";

import character from "../../../assets/images/holecharacterHB.png";
import SessionButton from "../../Session/SessionButton/SessionButton";
import UserContext from "../../../store/user-context";

function SessionFourP4(props) {
  const navigate = useNavigate();
  const userCtx = useContext(UserContext);

  const handleClick = () => {
    navigate(`/sessions/${props.name}/5`);
  };

  return (
    <>
      <div className={classes.sessionText}>
        <p>
          می‌خواهیم ببینیم چطور میشه این فکر و احساس رو از خودت جدا کنی و یاد
          بگیری فقط فکر و احساس هستند که داری شون، و می‌تونن از تو جدا بشن و
          لزوما مساوی با هویت تو نیستند
        </p>
      </div>
      <div
        className={`${classes.characterContainer} ${classes.w60}`}
        style={{ height: "50%" }}
      >
        <div className={classes.circles}>
          <div className={classes.circle}></div>
          <div className={classes.circle}></div>
        </div>
        <div className={classes.character}>
          <img src={character} alt="" />
          <p>{userCtx.user.username}</p>
        </div>
      </div>
      <SessionButton text={"بعدی"} onClick={handleClick} />
    </>
  );
}

export default SessionFourP4;
