import React from "react";

import classes from "./Button.module.css";

import forward from "../../assets/images/goForward.png";

function Button(props) {
  return (
    <button className={classes.button} onClick={() => props.onClick(true)}>
      {props.title}
      {props.isSessiontButton && <img src={forward} alt="flash" />}
    </button>
  );
}

export default Button;
