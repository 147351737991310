import React, { useState } from "react";

import classes from "./InputSettings.module.css";
import passHide from "../../../assets/images/passHide.svg";
import passShow from "../../../assets/images/passShow.svg";

function InputSettings(props) {
  const [showPass, setShowPass] = useState(false);

  return (
    <div
      className={
        props.active ? classes.inputControlActive : classes.inputControl
      }
    >
      <label htmlFor={props.name}>{props.title}</label>
      {props.active ? (
        <input
          type={"text"}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          className={!showPass & (props.name === "pass") && classes.password}
        />
      ) : (
        <input
          type={"text"}
          value={props.value}
          className={!showPass & (props.name === "pass") && classes.password}
        />
      )}
      {props.name === "pass" && props.active && (
        <img
          src={showPass ? passShow : passHide}
          alt=""
          onClick={() => setShowPass((prev) => !prev)}
          className={classes.passEye}
        />
      )}
    </div>
  );
}

export default InputSettings;
