import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { UserContextProvider } from "./store/user-context";
import "./index.css";
import App from "./App";
import Preloader from "./components/Preloader/Preloader";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <UserContextProvider>
      <BrowserRouter>
        <Preloader>
          <App />
        </Preloader>
      </BrowserRouter>
    </UserContextProvider>
  </React.StrictMode>
);
