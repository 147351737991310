import React from "react";
import Header from "./Header";
import TabBar from "../TabBar/TabBar";

import classes from "./Layout.module.css";

function Layout(props) {
  return (
    <>
      <Header title={props.title} hasBack={props.isSessionPage} />
      <main
        className={`${classes.layout} ${
          !props.isSession12First && classes[props.name]
        }`}
      >
        {props.children}
        {!props.isSessionPage && (
          <div className={classes.control}>
            <TabBar />
          </div>
        )}
      </main>
    </>
  );
}

export default Layout;
