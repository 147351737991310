import React from "react";

import classes from "./AuthButton.module.css";

function AuthButton(props) {
  return (
    <button className={classes.blueButton} onClick={props.onClick} {...props}>
      {props.children}
    </button>
  );
}

export default AuthButton;
