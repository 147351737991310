import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SessionButton from "../../Session/SessionButton/SessionButton";

import classes from "./SessionSeven.module.css";

import character from "../../../assets/images/characterNormal.png";
import background from "../../../assets/images/session7bg1.png";
import video from "../../../assets/videos/session7.mp4";
import ReactPlayer from "react-player";

function SessionSevenP2(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    navigate(`/sessions/${props.name}/3`, { state: location.state });
  };
  return (
    <>
      {/* <img src={background} className={classes.backgroundNormal} alt="" /> */}
      <ReactPlayer
        url={video}
        light={
          <img
            src={background}
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
        }
        width={"100%"}
        height={"75%"}
        controls={true}
        className={classes.video}
      />
      <div className={classes.characterSmall}>
        <p>اینجا یک بخشی از طبیعت هست. انگار اومدی توش.</p>
        <div style={{ width: "25%" }}>
          <img src={character} alt="" className={classes.characterImg} />
        </div>
      </div>
      <SessionButton text={"بعدی"} onClick={handleClick} />
    </>
  );
}

export default SessionSevenP2;
