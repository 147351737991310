import React from "react";

import classes from "./StartPage.module.css";
import character from "../../../assets/images/authCharacter.svg";
import { useNavigate } from "react-router-dom";

function StartPage() {
  const navigate = useNavigate();

  const handelLoginNavigate = () => {
    navigate("/auth/login");
  };

  const handleSignUpNavigate = () => [navigate("/auth/register")];

  return (
    <div className={classes.container}>
      <div className={classes.layout}>
        <h1 className={classes.title}>به مربیِ آرامِ من خوش اومدی!</h1>
        <img src={character} alt="" />
        <div className={classes.buttonContainer}>
          <button className={classes.loginButton} onClick={handelLoginNavigate}>
            ورود
          </button>
          <button
            className={classes.registerButton}
            onClick={handleSignUpNavigate}
          >
            ایجاد حساب جدید
          </button>
        </div>
      </div>
    </div>
  );
}

export default StartPage;
