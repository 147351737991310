import React from "react";
import Layout from "../../components/Layout/Layout";
import Home from "../../components/Home/Home";

function HomePage() {
  return (
    <Layout title={"مربی آرام من"}>
      <Home />
    </Layout>
  );
}

export default HomePage;
