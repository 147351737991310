import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import classes from "./SessionFour.module.css";

import character from "../../../assets/images/characterBody.png";
import brain from "../../../assets/images/brain.png";
import heart from "../../../assets/images/heart.png";
import SessionButton from "../../Session/SessionButton/SessionButton";
import UserContext from "../../../store/user-context";

const face =
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNzciIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA3NyA1MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGVsbGlwc2UgY3g9IjY0Ljc1NDYiIGN5PSIzNC4yNTM0IiByeD0iOC41NTE4MiIgcnk9IjEzLjUiIHRyYW5zZm9ybT0icm90YXRlKDE1IDY0Ljc1NDYgMzQuMjUzNCkiIGZpbGw9IiNGQ0FGOUEiLz4KPGVsbGlwc2UgY3g9IjE2LjA1MTMiIGN5PSIzMC40MDQ2IiByeD0iMTMiIHJ5PSIxMy41IiB0cmFuc2Zvcm09InJvdGF0ZSgxNSAxNi4wNTEzIDMwLjQwNDYpIiBmaWxsPSIjRkNBRjlBIi8+CjxlbGxpcHNlIGN4PSIyOC42ODU0IiBjeT0iMTQuMDkwOCIgcng9IjkuOTM3NTYiIHJ5PSIxMS45MjUxIiB0cmFuc2Zvcm09InJvdGF0ZSgxNSAyOC42ODU0IDE0LjA5MDgpIiBmaWxsPSJibGFjayIvPgo8ZWxsaXBzZSBjeD0iNjQuODMzNSIgY3k9IjE1LjY2MTciIHJ4PSI4IiByeT0iMTIiIHRyYW5zZm9ybT0icm90YXRlKDE1IDY0LjgzMzUgMTUuNjYxNykiIGZpbGw9ImJsYWNrIi8+Cjwvc3ZnPgo=";

function SessionFourP7(props) {
  const navigate = useNavigate();
  const userCtx = useContext(UserContext);

  const handleClick = () => {
    navigate(`/sessions/${props.name}/8`);
  };

  return (
    <>
      <div className={classes.sessionText}>
        <p>
          حالا احساست رو فقط نگاه کن! انگار یک چیزیه که بیرون از توست لزوما علط
          یا درست نیست و مساوی با تو هم نیست تو فقط این احساس رو داری! بدون
          اینکه بخوای بگی خوبه یا بد فقط نگاهش کن!
        </p>
      </div>
      <div
        className={`${classes.characterContainer} ${classes.w60}`}
        style={{ height: "50%" }}
      >
        <div className={classes.circlesNotAnimated}>
          <div className={classes.circleNotAnimated}>
            <img src={brain} alt="" className={classes.brain} />
          </div>
          <div
            className={classes.circleNotAnimated}
            style={{ backgroundColor: "#70CAFF" }}
          >
            <img src={heart} alt="" className={classes.heart} />
          </div>
        </div>
        <div className={classes.characterNotAnimated}>
          <div style={{ position: "relative", height: "80%" }}>
            <img src={character} alt="" />
            <img src={face} alt="" className={classes.faceRD} />
          </div>
          <p>{userCtx.user.username}</p>
        </div>
      </div>
      <SessionButton text={"بعدی"} onClick={handleClick} />
    </>
  );
}

export default SessionFourP7;
