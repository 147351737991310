import React from "react";
import { Link, useLocation } from "react-router-dom";

import classes from "./TabBar.module.css";

import home from "../../assets/images/home.png";
import settings from "../../assets/images/settings.png";
import sessions from "../../assets/images/sessions.png";

const tabs = [
  { id: 1, image: sessions, alt: "جلسات", slug: "/sessions" },
  { id: 2, image: home, alt: "خانه", slug: "/" },
  { id: 3, image: settings, alt: "تنظیمات", slug: "/settings" },
];

function TabBar() {
  const location = useLocation();

  return (
    <div className={classes.container}>
      {tabs.map((tab) => (
        <Link to={tab.slug} key={tab.id}>
          <div className={location.pathname === tab.slug ? classes.active : ""}>
            <img src={tab.image} alt={tab.alt} />
          </div>
        </Link>
      ))}
    </div>
  );
}

export default TabBar;
