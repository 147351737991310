import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Card from "../../Ui/Card";
import SessionButton from "../../Session/SessionButton/SessionButton";

import classes from "./SessionSix.module.css";

import character from "../../../assets/images/characterIce.png";
import ice from "../../../assets/images/ice.png";
import UserContext from "../../../store/user-context";

function SessionSix(props) {
  const navigate = useNavigate();
  const userCtx = useContext(UserContext);

  const handleClick = () => {
    navigate(`/sessions/${props.name}/2`);
  };

  return (
    <>
      <div className={classes.cardContainer}>
        <Card
          text={`اینجا می‌خواهیم کمی تمرین تحمل کردن کنیم.
             وقتی آشفته می‌شیم  یک گزینه میتونه این باشه که کمی تحمل کنیم.
             این یخ رو ببین. تصور کن واقعیه.`}
        />
      </div>
      <div className={classes.character}>
        <div style={{ position: "relative", width: "50%" }}>
          <img src={character} alt="" style={{ width: "100%" }}></img>
          <img src={ice} alt="" className={classes.ice} />
        </div>
        <p>{userCtx.user.username}</p>
      </div>
      <SessionButton text={"بزن بریم!"} onClick={handleClick} />
    </>
  );
}

export default SessionSix;
