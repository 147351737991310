import axios from "axios";

export const getSession = async (session) => {
  const token = localStorage.getItem("token");

  const res = await axios.get(`https://api.morabiarameman.socialin.co/sessions/${session}/`, {
    headers: {
      Authorization: "Bearer " + JSON.parse(token),
    },
  });

  return res.data;
};

export const setSessionDone = (session, data, navigate, getSessions) => {
  const token = localStorage.getItem("token");

  axios
    .put(
      `https://api.morabiarameman.socialin.co/sessions/update/${session}/`,
      {
        done: true,
        data: data,
      },
      {
        headers: {
          Authorization: "Bearer " + JSON.parse(token),
        },
      }
    )
    .then((res) => {
      navigate();
      getSessions(JSON.parse(token));
    });
};
