import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { setSessionDone } from "../../../api/sessionsApi";

import SessionButton from "../../Session/SessionButton/SessionButton";

import classes from "./SessionSeven.module.css";

import background from "../../../assets/images/session7bgSmall.png";
import UserContext from "../../../store/user-context";
import HomeModal from "../../Home/modal/HomeModal";

const face =
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCA1MCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGVsbGlwc2UgY3g9IjQyLjUyMTkiIGN5PSIxMS45MjkiIHJ4PSI2LjUyMTg1IiByeT0iNS45Mjg5NSIgZmlsbD0iI0ZDQUY5QSIvPgo8ZWxsaXBzZSBjeD0iNi41MjE4NSIgY3k9IjExLjkyOSIgcng9IjYuNTIxODUiIHJ5PSI1LjkyODk1IiBmaWxsPSIjRkNBRjlBIi8+CjxlbGxpcHNlIGN4PSIxMS4zNzkzIiBjeT0iNS41OTUzNCIgcng9IjQuNDQ2NzIiIHJ5PSI1LjMzNjA2IiBmaWxsPSJibGFjayIvPgo8ZWxsaXBzZSBjeD0iMzguMzU1OSIgY3k9IjUuNTk1MzQiIHJ4PSI0LjQ0NjcyIiByeT0iNS4zMzYwNiIgZmlsbD0iYmxhY2siLz4KPHBhdGggZD0iTTIyIDE1QzI1LjQwOTEgMTYuNDgyMiAyNy4zMzYxIDE2LjYzMDUgMzEuMDQxNyAxNSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==";
const character =
  "data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iOTQiIGhlaWdodD0iMTIyIiB2aWV3Qm94PSIwIDAgOTQgMTIyIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMjMuNTE0MSA5NC42MTMzQzIzLjY3NzcgOTAuODUwOSAyNi41ODEzIDgyLjk1NzkgMjguMDEyNyA3OS40ODE4QzI4LjAxMjcgNzkuMjc3MyAyOC4yMTcxIDc4Ljc0NTcgMjkuMDM1MSA3OC4yNTQ5QzI5LjI3NTEgNzguMTEwOSAyOS42MDU0IDc4LjAwMDcgMjkuOTY1IDc3LjkxNjNDMjcuMjc4IDc3Ljg2MTQgMjIuMTY2OCA3Ni42NjUyIDEzLjk0MDcgNzAuMjk2NkMxLjI2MzA2IDYwLjQ4MTYgLTEuMTkwODYgMzkuNDIgNS45NjYwMSAyNi4xMjg3QzE0LjA4NzQgMTEuMDQ2MiAyOC4wMTI3IDIgNDkuODkyMSAyQzcyLjgzMTcgMiA4OC4yOTgzIDE5Ljc4OTggOTEuMjM0NSAzNC4zMDhDOTQuOTE1MiA1Mi41MDY3IDg2LjczNiA2Ni42MTU5IDc1LjA0MzIgNzQuMTY1M0M2Ni43OTU3IDc5LjQ5MDIgNjAuMTE2MSA3OC40NTk0IDYwLjcyOTYgNzkuNDgxOEM2MS4yMjAzIDgwLjI5OTcgNjQuMzQyMSA3OS4xNDEgNjUuODQxNiA3OC40NTk0QzY2LjExNDMgNzguODAwMiA2Ni45MDQ5IDgwLjA1NDMgNjcuODg2NCA4Mi4zNDQ1QzY5LjExMzMgODUuMjA3MiA2OS45MzEyIDkwLjUyMzcgNjkuMzE3OCA5OC4yOTRDNjguNzA0MyAxMDYuMDY0IDYzLjU5MjMgMTAzLjIwMiA2Mi45Nzg5IDEwMy4yMDJDNjIuMzY1NCAxMDMuMjAyIDYyLjM2NTQgOTUuMDIyMyA2Mi4zNjU0IDkyLjc3M0M2Mi4zNjU0IDkwLjk3MzYgNjEuNTQ3NSA4NS4zOTUzIDYxLjEzODYgODQuMTg0OEM2MC43Mjk2IDgyLjk3NDQgNjIuNDA2MyAxMDYuMDY0IDYxLjc1MiAxMTMuNDI2QzYwLjkzNDEgMTIyLjYyNyA1MS45MzY5IDExOS41NiA1MC43MSAxMTcuMTA2QzQ5LjcyODUgMTE1LjE0MyA0OS43NTU4IDEwNy40MjcgNDkuODkyMSAxMDMuODE1SDQ0LjU3NTZDNDQuNjQzOCAxMDQuMTU2IDQ0LjYxNjUgMTA2LjMxIDQzLjk2MjIgMTEyLjE5OUM0My4xNDQyIDExOS41NiAzOS4yNTkxIDEyMC4xNzMgMzYuMzk2NCAxMTkuOTY5QzMzLjUzMzYgMTE5Ljc2NCAzMC42NzA5IDExNC42NTIgMzAuMDU3NSAxMDYuMDY0QzI5Ljk1NDEgMTA0LjYxNyAzMC4wMDE3IDEwMi45NzcgMzAuMTQ1NiAxMDEuMjYyQzMwLjEwNzkgMTAxLjU3IDMwLjA3ODQgMTAxLjc0OSAzMC4wNTc1IDEwMS43N0MyOS44NTMgMTAxLjk3NSAzMC4wNTc1IDEwMi4xNzkgMjguMDEyNyAxMDEuNzdDMjUuOTY3OSAxMDEuMzYxIDIzLjMwOTYgOTkuMzE2NCAyMy41MTQxIDk0LjYxMzNaIiBmaWxsPSJ3aGl0ZSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIzIi8+Cjwvc3ZnPgo=";

function SessionSevenP4(props) {
  const navigate = useNavigate();
  const [things, setThings] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const location = useLocation();
  const userCtx = useContext(UserContext);

  const closeModalhandler = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    setThings(location.state.things);
  }, [location]);

  const handleClick = async () => {
    if (things !== "") {
      setSessionDone(
        props.name.substring(7, 8),
        things,
        () => navigate(`/sessions`),
        (val) => userCtx.getSessions(val)
      );
    } else {
      setModalOpen(true);
    }
  };
  return (
    <>
      <img
        src={background}
        className={classes.backgroundNormalNotAnimated}
        style={{ height: "30vh" }}
        alt=""
      />
      <div className={classes.inputContiner}>
        <HomeModal onClose={closeModalhandler} isOpen={modalOpen}>
          <p className={classes.modalText}>هر چی دیدی رو بنویس :)</p>
          <button className={classes.modalButton} onClick={closeModalhandler}>
            بستن
          </button>
        </HomeModal>
        <textarea
          name="items"
          id=""
          placeholder="حالا هر چی دیدی رو فقط نام ببر."
          cols="35"
          rows="18"
          value={things}
          onChange={(e) => setThings(e.target.value)}
        ></textarea>
      </div>
      <div
        className={classes.characterSmall}
        style={{ justifyContent: "flex-end" }}
      >
        <div style={{ position: "relative" }}>
          <img
            src={character}
            alt=""
            className={classes.characterImgNotAnimated}
            style={{ width: "12vh" }}
          />
          <img src={face} alt="" className={classes.faceNormal} />
        </div>
      </div>
      <SessionButton text={"ذخیره"} onClick={handleClick} />
    </>
  );
}

export default SessionSevenP4;
