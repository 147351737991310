import React, { useContext, useState } from "react";
import InputAuth from "../AuthInput/AuthInput";
import AuthButton from "../AuthButton/AuthButton";
import { Link, useNavigate } from "react-router-dom";
import UserContext from "../../../store/user-context";
import { OrbitProgress } from "react-loading-indicators";

import classes from "./AuthLogin.module.css";
import character from "../../../assets/images/authCharacter.svg";
import ErrorMessage from "../../Ui/ErrorMessage";

function AuthLogin() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const userCtx = useContext(UserContext);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  return (
    <div className={classes.container}>
      <div className={classes.layout}>
        <h1 className={classes.title}>به مربیِ آرامِ من خوش اومدی!</h1>
        <div className={classes.card}>
          <img src={character} alt="" className={classes.character} />
          <div className={classes.cardContent}>
            <InputAuth
              name={"username"}
              title={"نام کاربری*"}
              value={username}
              onChange={setUsername}
            />
            <InputAuth
              name={"pass"}
              title={"رمز عبور*"}
              value={password}
              onChange={setPassword}
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <div style={{ width: "100%" }}>
              <AuthButton
                onClick={() => {
                  userCtx.logUserIn(
                    username,
                    password,
                    () => navigate("/"),
                    (val) => setError(val),
                    (val) => setLoading(val)
                  );
                }}
              >
                {loading ? (
                  <OrbitProgress
                    color="#ffffff"
                    size="small"
                    text=""
                    textColor=""
                    style={{ fontSize: "6px" }}
                  />
                ) : (
                  "ورود"
                )}
              </AuthButton>
            </div>
            <p className={classes.dontHaveAccount}>
              حساب نداری؟ <Link to={"/auth/register"}>حساب جدید بساز</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthLogin;
