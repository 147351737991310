import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import SessionButton from "../../Session/SessionButton/SessionButton";

import { setSessionDone, getSession } from "../../../api/sessionsApi";

import classes from "./SessionTweleve.module.css";
import survey from "../../../assets/images/survey.png";
import UserContext from "../../../store/user-context";

function SessionTweleveP2(props) {
  const navigate = useNavigate();
  const userCtx = useContext(UserContext);

  const handleClick = async () => {
    const sessionData = await getSession(props.name.substring(7, 9));
    if (!sessionData.done) {
      setSessionDone(
        props.name.substring(7, 9),
        null,
        () => navigate(`/sessions`),
        (val) => userCtx.getSessions(val)
      );
    } else {
      navigate(`/sessions`);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.surveyContainer}>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLScvPfgnVf-tJeLk6fct9D2kkzWw3vEw_FuvdzkrRnU3-qdAGQ/viewform?vc=0&c=0&w=1&flr=0&usp=mail_form_link"
          style={{ width: "51%", height: "65%" }}
        >
          <img src={survey} alt="" style={{ width: "100%", height: "100%" }} />
        </a>
        <p>شرکت در پرسشنامه</p>
      </div>
      <div className={classes.surveyText}>
        <p>
          حالا که تا اینجا اومدی و مراحل رو تموم کردی، لطفا روی لینک زیر برو و
          به سوالایی که هست جواب بده و در نهایت با ارسال جوابها کارت هدیه‌ات رو
          بگیر{" "}
        </p>
      </div>
      <SessionButton text={"پایان"} onClick={handleClick} />
    </div>
  );
}

export default SessionTweleveP2;
