import React, { useContext, useState } from "react";
import InputAuth from "../AuthInput/AuthInput";
import AuthButton from "../AuthButton/AuthButton";
import { Link, useNavigate } from "react-router-dom";
import { OrbitProgress } from "react-loading-indicators";

import classes from "./AuthRegister.module.css";
import character from "../../../assets/images/authCharacter.svg";
import UserContext from "../../../store/user-context";
import { isValidEmail } from "../../../utils/utils";
import ErrorMessage from "../../Ui/ErrorMessage";

function AuthRegister() {
  const userCtx = useContext(UserContext);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  return (
    <div className={classes.container}>
      <div className={classes.layout}>
        <h1 className={classes.title}>به مربیِ آرامِ من خوش اومدی!</h1>
        <div className={classes.card}>
          <img src={character} alt="" className={classes.character} />
          <div className={classes.cardContent}>
            <InputAuth
              name={"username"}
              title={"نام کاربری*"}
              value={username}
              onChange={(val) => setUsername(val)}
            />
            <InputAuth
              name={"pass"}
              title={"رمز عبور*"}
              value={password}
              onChange={(val) => setPassword(val)}
            />
            <InputAuth
              name={"email"}
              title={"ایمیل"}
              value={email}
              onChange={(val) => setEmail(val)}
            />
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <div style={{ width: "100%", marginTop: "10px" }}>
              <AuthButton
                onClick={() => {
                  if (isValidEmail(email)) {
                    userCtx.signUp(
                      email,
                      password,
                      username,
                      () => navigate("/auth/login"),
                      (val) => setLoading(val),
                      (val) => setError(val)
                    );
                  } else {
                    setError("ایمیل نامعتبر است.");
                  }
                }}
              >
                {loading ? (
                  <OrbitProgress
                    color="#ffffff"
                    size="small"
                    text=""
                    textColor=""
                    style={{ fontSize: "6px" }}
                  />
                ) : (
                  "ساختن حساب"
                )}
              </AuthButton>
            </div>
            <p className={classes.dontHaveAccount}>
              حساب داری؟ <Link to={"/auth/login"}>از اینجا وارد شو</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthRegister;
