import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import SessionButton from "../../Session/SessionButton/SessionButton";
import { setSessionDone, getSession } from "../../../api/sessionsApi";

import classes from "./SessionFive.module.css";

import character from "../../../assets/images/characterBreathIn.png";
import characterBO from "../../../assets/images/characterBreathOut.png";
import UserContext from "../../../store/user-context";
import voice from "../../../assets/voices/session5.m4a";
import Modal from "../../Ui/Modal";

function SessionFive(props) {
  const navigate = useNavigate();
  const [characterImg, setCharacterImg] = useState(character);
  const userCtx = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(true);

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const timer = setTimeout(
      () =>
        setCharacterImg((prev) =>
          prev === character ? characterBO : character
        ),
      5000
    );

    return () => clearTimeout(timer);
  }, [characterImg]);

  const handleClick = async () => {
    const sessionData = await getSession(props.name.substring(7, 8));
    if (!sessionData.done) {
      setSessionDone(
        props.name.substring(7, 8),
        null,
        () => navigate(`/sessions`),
        (val) => userCtx.getSessions(val)
      );
    } else {
      navigate(`/sessions`);
    }
  };
  return (
    <>
      <AudioPlayer
        autoPlay={false}
        src={voice}
        onPlay={(e) => console.log(e)}
        onPause={(e) => console.log(e)}
        onEnded={(e) => console.log(e)}
        header={<p className={classes.text}>تمرین حباب تنفس</p>}
        style={{ height: "23%" }}
      />
      <div className={classes.characterMain}>
        <img src={characterImg} alt="" />
        <p>{userCtx.user.username}</p>
      </div>
      <SessionButton text={"بعدی"} onClick={handleClick} />
      <Modal isOpen={isOpen} onClose={closeModal} buttonContent={"بزن بریم"}>
        <p>
          یاد گرفتیم از فکر و احساس فاصله بگیریم و توجهمون رو جای دیگه بگذاریم
          و  حالا اولین جایگزین  برای توجه کردن میشه تنفسمون!
        </p>
      </Modal>
    </>
  );
}

export default SessionFive;
