import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import classes from "./Home.module.css";

function ContinueSession() {
  const [currentSession, setCurrentSession] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      const sessions = localStorage.getItem("sessions");
      const array = Object.keys(JSON.parse(sessions)).map((key) => {
        return { name: key, status: JSON.parse(sessions)[key] };
      });

      if (array.filter((s) => s.status === "undone").length !== 0) {
        setCurrentSession(array.filter((s) => s.status === "undone")[0].name);
      }
      setLoading(false);
    }, 500);
  }, []);

  return (
    <div className={classes.card}>
      {currentSession === "" && !loading ? (
        <p>همه جلسه هارو تموم کردی :)</p>
      ) : (
        <>
          <p>دوست داری جلسه امروز رو شروع کنیم؟</p>
          <button>
            <Link to={`/sessions/${currentSession}/1`}>
              رفتن به جلسه {currentSession.substring(7, 9)}
            </Link>
          </button>
        </>
      )}
    </div>
  );
}

export default ContinueSession;
