import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import SessionButton from "../../Session/SessionButton/SessionButton";
import { setSessionDone, getSession } from "../../../api/sessionsApi";

import Modal from "../../Ui/Modal";

import classes from "./SessionNine.module.css";

// import message from "../../../assets/images/chat-bubble.png";
import message from "../../../assets/images/message.png";
import character from "../../../assets/images/herocharacter.png";
import Carousel from "../../Ui/Carousel";
import UserContext from "../../../store/user-context";

const messages = [
  "این هم یک موقعیت هست، اشکالی نداره که عصبانی باشم ولی قرار نیست پرخاش کنم، فقط به احساسم توجه می‌کنم",
  "می‌گذارم هر چی درونم هست باشه و بگذره.",
  "اگر لازم باشه و بتونم احساسم رو درست بهشون ابراز می‌کنم ( مثلا من از این حرفتون عصبانی شدم، ازتون انتظار  دارم دفعه بعد تکرارش نکنید)",
];

function SessionNineP3(props) {
  const navigate = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(true);
  const userCtx = useContext(UserContext);

  function closeModal() {
    setIsOpen(false);
  }

  const handleClick = async () => {
    const sessionData = await getSession(props.name.substring(7, 8));
    if (!sessionData.done) {
      setSessionDone(
        props.name.substring(7, 8),
        null,
        () => navigate(`/sessions`),
        (val) => userCtx.getSessions(val)
      );
    } else {
      navigate(`/sessions`);
    }
  };

  return (
    <>
      <div style={{ position: "relative", height: "45%" }}>
        <img src={message} alt="" className={classes.message} />
        <div className={classes.carousel}>
          <Carousel contents={messages} />
        </div>
      </div>
      <div className={classes.characterMain}>
        <img src={character} alt="" />
        <p>{userCtx.user.username}</p>
      </div>
      <Modal isOpen={modalIsOpen} onClose={closeModal}>
        <p>موقعیت‌هایی که با اعضای خانواده و دوستان به چالش و بحث می‌رسی</p>
      </Modal>
      <SessionButton text={"بعدی"} onClick={handleClick} />
    </>
  );
}

export default SessionNineP3;
