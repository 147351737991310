import React from "react";
import { Link } from "react-router-dom";

import classes from "./SessionItem.module.css";

function SessionItem(props) {
  return (
    <div className={classes.session}>
      <Link to={`/sessions/${props.slug}/1`}>
        <div className={props.done === "done" && classes.done}>
          <img className={classes.image} src={props.image} alt={props.title} />
          <svg
            width="17"
            height="18"
            viewBox="0 0 17 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={classes.tick}
            style={
              props.done === "done" ? { display: "block" } : { display: "none" }
            }
          >
            <path
              d="M3 11.125L6.0845 14.8532C6.53743 15.4006 7.40079 15.3143 7.7364 14.6881L14 3"
              stroke="white"
              stroke-width="5"
              stroke-linecap="round"
            />
            <path
              d="M3 11.125L6.0845 14.8532C6.53743 15.4006 7.40079 15.3143 7.7364 14.6881L14 3"
              stroke="#17A267"
              stroke-width="3"
              stroke-linecap="round"
            />
          </svg>
        </div>
        <p>{props.title}</p>
      </Link>
    </div>
  );
}

export default SessionItem;
