import React from "react";
import Button from "../../Ui/Button";

import classes from "./SessionButton.module.css";

function SessionButton(props) {
  return (
    <div className={classes.control}>
      <Button
        title={props.text}
        isSessiontButton={true}
        onClick={() => props.onClick()}
      />
    </div>
  );
}

export default SessionButton;
