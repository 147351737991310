import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

const UserContext = createContext({
  token: null,
  user: {},
  sessions: {},
  getSessions: () => {},
  signUp: (email, password, username, navigate, setLoading) => {},
  logUserIn: (usernameORemial, password, navigate, setError, setLoading) => {},
  changePass: (
    old_password,
    new_password,
    navigate,
    setError,
    setLoading
  ) => {},
  logUserOut: () => {},
});

const initialCurrUserValue = () => {
  const currUser = localStorage.getItem("user");
  return currUser ? JSON.parse(currUser) : {};
};

const initialSessionsValue = () => {
  const sessions = localStorage.getItem("sessions");
  return sessions ? JSON.parse(sessions) : {};
};

const initialTokenValue = () => {
  const token = localStorage.getItem("token");
  return token ? token : null;
};

export const UserContextProvider = (props) => {
  const [loggedInUser, setLoggedInUser] = useState(initialCurrUserValue);
  const [token, setToken] = useState(initialTokenValue);
  const [session, setSessions] = useState(initialSessionsValue);

  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(loggedInUser));
  }, [loggedInUser]);

  useEffect(() => {
    localStorage.setItem("token", token);
  }, [token]);

  useEffect(() => {
    localStorage.setItem("sessions", JSON.stringify(session));
  }, [session]);

  const signUpHandler = (
    email,
    password,
    username,
    navigate,
    setLoading,
    setError
  ) => {
    setLoading(true);
    axios
      .post("https://api.morabiarameman.socialin.co/accounts/register/", {
        username: username,
        password: password,
        email: email,
      })
      .then((res) => {
        if (res.status === 200) {
          navigate();
        }
      })
      .catch((err) => {
        console.log(!err.response.data.email);
        if (err.response.data.email) {
          setError("این ایمیل قبلا ثبت شده");
        }
        if (err.response.data.username) {
          setError("نام کاربری تکراری است");
        }
        if (err.response.data.password) {
          setError("پسورد باید حداقل ۶ کاراکتر باشد");
        }
      })
      .finally(() => setLoading(false));
  };

  const logUserInHandler = (
    usernameORemial,
    password,
    navigate,
    setError,
    setLoading
  ) => {
    setLoading(true);
    axios
      .post("https://api.morabiarameman.socialin.co/accounts/login/", {
        username_or_email: usernameORemial,
        password: password,
      })
      .then((res) => {
        const user = res.data.user;
        const token = res.data.access_token;
        const parsedUser = JSON.stringify(user);
        setToken(JSON.stringify(token));
        localStorage.setItem("token", JSON.stringify(token));
        setLoggedInUser(user);
        localStorage.setItem("user", parsedUser);
        getUsersSessions(token);
        localStorage.setItem("mood", JSON.stringify(1));
        navigate();
      })
      .catch((err) => {
        if (err.response.data.detail === "Please username_or_email, password") {
          setError("لطفا نام کاربری (ایمیل) و پسورد را وارد کنید.");
        }
        if (err.response.data.detail === "Invalid email or password.") {
          setError("نام کاربری (ایمیل) یا پسورد اشتباه است.");
        }
      })
      .finally(() => setLoading(false));
  };

  const changePasswordHandler = (
    old_password,
    new_password,
    navigate,
    setError,
    setLoading
  ) => {
    setLoading(true);
    axios
      .put(
        "https://api.morabiarameman.socialin.co/accounts/change-password/",
        {
          old_password: old_password,
          new_password: new_password,
        },
        {
          headers: {
            Authorization: "Bearer " + JSON.parse(token),
          },
        }
      )
      .then((res) => navigate())
      .catch((err) => setError("پسورد قبلی اشتباه است."))
      .finally(() => setLoading(false));
  };

  const getUsersSessions = (thisToken) => {
    axios
      .get("https://api.morabiarameman.socialin.co/sessions/", {
        headers: {
          Authorization: "Bearer " + thisToken,
        },
      })
      .then((res) => {
        setSessions(res.data);
        localStorage.setItem("sessions", JSON.stringify(res.data));
      });
  };

  const logUserOutHandler = (navigate) => {
    setLoggedInUser({});
    setToken(null);
    setSessions({});
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("sessions");
    localStorage.removeItem("mood");
    navigate();
  };

  const context = {
    token: token,
    user: loggedInUser,
    sessions: session,
    getSessions: getUsersSessions,
    signUp: signUpHandler,
    logUserIn: logUserInHandler,
    changePass: changePasswordHandler,
    logUserOut: logUserOutHandler,
  };

  return (
    <UserContext.Provider value={context}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContext;
