import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import SessionButton from "../../Session/SessionButton/SessionButton";

import classes from "./SessionSeven.module.css";

import background from "../../../assets/images/session7bg1.png";
import video from "../../../assets/videos/session7.mp4";
import ReactPlayer from "react-player";

const face =
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCA1MCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGVsbGlwc2UgY3g9IjQyLjUyMTkiIGN5PSIxMS45MjkiIHJ4PSI2LjUyMTg1IiByeT0iNS45Mjg5NSIgZmlsbD0iI0ZDQUY5QSIvPgo8ZWxsaXBzZSBjeD0iNi41MjE4NSIgY3k9IjExLjkyOSIgcng9IjYuNTIxODUiIHJ5PSI1LjkyODk1IiBmaWxsPSIjRkNBRjlBIi8+CjxlbGxpcHNlIGN4PSIxMS4zNzkzIiBjeT0iNS41OTUzNCIgcng9IjQuNDQ2NzIiIHJ5PSI1LjMzNjA2IiBmaWxsPSJibGFjayIvPgo8ZWxsaXBzZSBjeD0iMzguMzU1OSIgY3k9IjUuNTk1MzQiIHJ4PSI0LjQ0NjcyIiByeT0iNS4zMzYwNiIgZmlsbD0iYmxhY2siLz4KPHBhdGggZD0iTTIyIDE1QzI1LjQwOTEgMTYuNDgyMiAyNy4zMzYxIDE2LjYzMDUgMzEuMDQxNyAxNSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiLz4KPC9zdmc+Cg==";
const character =
  "data:image/svg+xml;base64, PHN2ZyB3aWR0aD0iOTQiIGhlaWdodD0iMTIyIiB2aWV3Qm94PSIwIDAgOTQgMTIyIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNMjMuNTE0MSA5NC42MTMzQzIzLjY3NzcgOTAuODUwOSAyNi41ODEzIDgyLjk1NzkgMjguMDEyNyA3OS40ODE4QzI4LjAxMjcgNzkuMjc3MyAyOC4yMTcxIDc4Ljc0NTcgMjkuMDM1MSA3OC4yNTQ5QzI5LjI3NTEgNzguMTEwOSAyOS42MDU0IDc4LjAwMDcgMjkuOTY1IDc3LjkxNjNDMjcuMjc4IDc3Ljg2MTQgMjIuMTY2OCA3Ni42NjUyIDEzLjk0MDcgNzAuMjk2NkMxLjI2MzA2IDYwLjQ4MTYgLTEuMTkwODYgMzkuNDIgNS45NjYwMSAyNi4xMjg3QzE0LjA4NzQgMTEuMDQ2MiAyOC4wMTI3IDIgNDkuODkyMSAyQzcyLjgzMTcgMiA4OC4yOTgzIDE5Ljc4OTggOTEuMjM0NSAzNC4zMDhDOTQuOTE1MiA1Mi41MDY3IDg2LjczNiA2Ni42MTU5IDc1LjA0MzIgNzQuMTY1M0M2Ni43OTU3IDc5LjQ5MDIgNjAuMTE2MSA3OC40NTk0IDYwLjcyOTYgNzkuNDgxOEM2MS4yMjAzIDgwLjI5OTcgNjQuMzQyMSA3OS4xNDEgNjUuODQxNiA3OC40NTk0QzY2LjExNDMgNzguODAwMiA2Ni45MDQ5IDgwLjA1NDMgNjcuODg2NCA4Mi4zNDQ1QzY5LjExMzMgODUuMjA3MiA2OS45MzEyIDkwLjUyMzcgNjkuMzE3OCA5OC4yOTRDNjguNzA0MyAxMDYuMDY0IDYzLjU5MjMgMTAzLjIwMiA2Mi45Nzg5IDEwMy4yMDJDNjIuMzY1NCAxMDMuMjAyIDYyLjM2NTQgOTUuMDIyMyA2Mi4zNjU0IDkyLjc3M0M2Mi4zNjU0IDkwLjk3MzYgNjEuNTQ3NSA4NS4zOTUzIDYxLjEzODYgODQuMTg0OEM2MC43Mjk2IDgyLjk3NDQgNjIuNDA2MyAxMDYuMDY0IDYxLjc1MiAxMTMuNDI2QzYwLjkzNDEgMTIyLjYyNyA1MS45MzY5IDExOS41NiA1MC43MSAxMTcuMTA2QzQ5LjcyODUgMTE1LjE0MyA0OS43NTU4IDEwNy40MjcgNDkuODkyMSAxMDMuODE1SDQ0LjU3NTZDNDQuNjQzOCAxMDQuMTU2IDQ0LjYxNjUgMTA2LjMxIDQzLjk2MjIgMTEyLjE5OUM0My4xNDQyIDExOS41NiAzOS4yNTkxIDEyMC4xNzMgMzYuMzk2NCAxMTkuOTY5QzMzLjUzMzYgMTE5Ljc2NCAzMC42NzA5IDExNC42NTIgMzAuMDU3NSAxMDYuMDY0QzI5Ljk1NDEgMTA0LjYxNyAzMC4wMDE3IDEwMi45NzcgMzAuMTQ1NiAxMDEuMjYyQzMwLjEwNzkgMTAxLjU3IDMwLjA3ODQgMTAxLjc0OSAzMC4wNTc1IDEwMS43N0MyOS44NTMgMTAxLjk3NSAzMC4wNTc1IDEwMi4xNzkgMjguMDEyNyAxMDEuNzdDMjUuOTY3OSAxMDEuMzYxIDIzLjMwOTYgOTkuMzE2NCAyMy41MTQxIDk0LjYxMzNaIiBmaWxsPSJ3aGl0ZSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIzIi8+Cjwvc3ZnPgo=";

function SessionSevenP3(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    navigate(`/sessions/${props.name}/4`, { state: location.state });
  };
  return (
    <>
      {/* <img
        src={background}
        className={classes.backgroundNormalNotAnimated}
        alt=""
      /> */}
      <ReactPlayer
        url={video}
        light={
          <img
            src={background}
            alt=""
            style={{ width: "100%", height: "100%" }}
          />
        }
        width={"100%"}
        height={"75%"}
        className={classes.video}
        controls={true}
      />
      <div className={classes.characterSmall}>
        <p>هرچی هست رو فقط نگاه کن. قراره فقط نگاه کنی و نام ببری.</p>
        <div style={{ position: "relative", width: "25%" }}>
          <img
            src={character}
            alt=""
            className={classes.characterImgNotAnimated}
          />
          <img src={face} alt="" className={classes.face} />
        </div>
      </div>
      <SessionButton text={"بعدی"} onClick={handleClick} />
    </>
  );
}

export default SessionSevenP3;
