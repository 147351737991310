import React from "react";
import AudioPlayer from "react-h5-audio-player";

import classes from "./SessionChat.module.css";
import chatBot from "../../../assets/images/chatbot.png";

function SessionChat(props) {
  return (
    <div className={classes.chat}>
      <img src={chatBot} alt="" />
      <div className={classes.chatContent}>
        <p>{props.message}</p>
        {props.img && <img src={props.img} alt="" />}
        {props.img && <AudioPlayer autoPlay={false} src={props.voice} />}
      </div>
    </div>
  );
}

export default SessionChat;
