import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Card from "../../Ui/Card";
import SessionButton from "../../Session/SessionButton/SessionButton";

import classes from "./SessionSeven.module.css";

import character from "../../../assets/images/characterNormal.png";
import background from "../../../assets/images/session7Background.png";
import { getSession } from "../../../api/sessionsApi";
import UserContext from "../../../store/user-context";
import Modal from "../../Ui/Modal";

function SessionSeven(props) {
  const navigate = useNavigate();
  const [things, setThings] = useState("");
  const userCtx = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(true);

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const getData = async () => {
      const res = await getSession(props.name.substring(7, 8));
      if (res.data !== null) {
        setThings(res.data);
      }
    };
    getData();
  }, [props.name]);

  const handleClick = () => {
    navigate(`/sessions/${props.name}/2`, { state: { things: things } });
  };
  return (
    <>
      <div
        className={classes.cardContainer}
        style={isOpen ? { display: "none" } : {}}
      >
        <Card text={"قرار هست بیایم روی حس بینایی تمرکز کنیم."} />
      </div>
      <div
        className={classes.character}
        style={isOpen ? { display: "none" } : {}}
      >
        <img src={character} alt="" style={{ width: "50%" }}></img>
        <p>{userCtx.user.username}</p>
      </div>
      <SessionButton text={"بزن بریم!"} onClick={handleClick} />
      <div className={classes.glass}></div>
      <img src={background} className={classes.background} alt="" />
      <Modal isOpen={isOpen} onClose={closeModal} buttonContent={"بزن بریم"}>
        <p>
          یک جایگزین دیگه برای اینکه توجهمون رو به اون سمت ببریم و به فکر و
          احساسمون گیر ندیم، حس های پنجگانه ماست. اینجا قراره روی حس بینایی
          تمرکز کنیم.
        </p>
      </Modal>
    </>
  );
}

export default SessionSeven;
