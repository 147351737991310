import React from "react";

import classes from "./ErrorMessage.module.css";

function ErrorMessage(props) {
  return (
    <p className={classes.error} {...props}>
      {props.children}
    </p>
  );
}

export default ErrorMessage;
