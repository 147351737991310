import React, { useContext, useState } from "react";
import MoodSelector from "./MoodSelector";
import ContinueSession from "./ContinueSession";

import classes from "./Home.module.css";
import happy from "../../assets/images/happy.png";
import chart from "../../assets/images/mood_chart.png";
import pencil from "../../assets/images/pencil.png";
import axios from "axios";
import UserContext from "../../store/user-context";
import HomeModal from "./modal/HomeModal";

function Home() {
  const [mood, setMood] = useState({ name: "خوشحال", image: happy, level: 1 });
  const [modalOpen, setModalOpen] = useState(false);
  const userCtx = useContext(UserContext);

  const modalCloseHandler = () => {
    setModalOpen(false);
  };

  const moodChangeHandler = () => {
    axios
      .post(
        "https://api.morabiarameman.socialin.co/feelings/add/",
        { level: mood.level },
        {
          headers: {
            Authorization: "Bearer " + JSON.parse(userCtx.token),
          },
        }
      )
      .then((res) => {
        localStorage.setItem("mood", res.data.level);
        setModalOpen(true);
      });
  };

  return (
    <>
      <ContinueSession />

      <div className={classes.range}>
        <MoodSelector setMood={setMood} />

        <img src={mood.image} alt="" className={classes.mood} />
        <img src={chart} alt="" className={classes.chart} />
      </div>
      <HomeModal isOpen={modalOpen} onClose={modalCloseHandler}>
        <p className={classes.modalText}>مودت عوض شد</p>
        <button className={classes.modalButton} onClick={modalCloseHandler}>
          بستن
        </button>
      </HomeModal>
      <div className={classes["mood-status"]}>
        <p>{mood.name}</p>
      </div>
      <div className={classes.actions}>
        <button onClick={moodChangeHandler}>
          ویرایش حال <img src={pencil} alt="" />
        </button>
      </div>
    </>
  );
}

export default Home;
