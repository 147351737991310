import React, { useState, useEffect } from "react";
import images from "../../utils/importImages";
import { FourSquare } from "react-loading-indicators";

const Preloader = ({ children }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const preloadImages = async () => {
      const promises = images.map((src) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = reject;
          window[src] = img;
        });
      });

      await Promise.all(promises);
      setLoading(false);
    };

    preloadImages();
  }, []);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <FourSquare color="#5FA8D3" size="medium" text="" textColor="" />
      </div>
    );
  }

  return <>{children}</>;
};

export default Preloader;
