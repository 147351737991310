import React, { useContext, useEffect, useState } from "react";
import Card from "../../Ui/Card";
import { useNavigate } from "react-router-dom";
import SessionButton from "../../Session/SessionButton/SessionButton";

import classes from "./SessionThree.module.css";

import character from "../../../assets/images/character.png";
import { getSession } from "../../../api/sessionsApi";
import UserContext from "../../../store/user-context";
import Modal from "../../Ui/Modal";

function SessionThree(props) {
  const navigate = useNavigate();
  const userCtx = useContext(UserContext);
  const [data, setData] = useState({
    thought1: "",
    thought2: "",
    thought3: "",
    feel1: "",
    feel2: "",
    feel3: "",
  });
  const [isOpen, setIsOpen] = useState(true);

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const getData = async () => {
      const currectData = await getSession(props.name.substring(7, 8));
      if (currectData.data !== null) {
        setData(JSON.parse(currectData.data));
      }
    };

    getData();
  }, [props.name]);

  const handleClick = () => {
    navigate(`/sessions/${props.name}/2`, { state: data });
  };

  return (
    <>
      <div style={isOpen ? { display: "none" } : { height: "55%" }}>
        <Card
          text={`حالا که یاد گرفتی هر طرح‌واره باعث میشه ما دنیا رو با عینک اون طرح‌واره ببینیم و بعد یک فکر و یک احساس خاص بکنیم بیا با هم تمرین کنیم:)
 چند موقعیتی که خیلی توی اونها آشفته شدی رو یادت بیار.  تو این موقعیت‌ها احتمالا یک طرح‌واره فعال شده...`}
        />
      </div>
      <div
        className={classes.characterMain}
        style={isOpen ? { display: "none" } : { height: "18%" }}
      >
        <img src={character} alt="" />
        <p>{userCtx.user.username}</p>
      </div>
      <SessionButton text={"بزن بریم!"} onClick={handleClick} />
      <Modal isOpen={isOpen} onClose={closeModal} buttonContent={"بزن بریم"}>
        <p>
          تو این جلسه می‌خواهیم فکر و احساس ناشی از طرحواره‌ها رو با هم شناسایی
          کنیم.
        </p>
      </Modal>
    </>
  );
}

export default SessionThree;
