import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import classes from "./SessionFour.module.css";

import character from "../../../assets/images/character.png";
import brain from "../../../assets/images/brain.png";
import heart from "../../../assets/images/heart.png";
import SessionButton from "../../Session/SessionButton/SessionButton";
import UserContext from "../../../store/user-context";

function SessionFourP5(props) {
  const navigate = useNavigate();
  const userCtx = useContext(UserContext);

  const handleClick = () => {
    navigate(`/sessions/${props.name}/6`);
  };
  return (
    <>
      <div className={classes.sessionText}>
        <p>
          حالا فکرت رو فقط نگاه کن! انگار یک چیزیه که بیرون از توست لزوما غلط یا
          درست نیست و مساوی با تو هم نیست تو فقط این فکر رو داری! بدون اینکه
          بخوای بگی خوبه یا بد فقط نگاهش کن!
        </p>
      </div>
      <div
        className={`${classes.characterContainer} ${classes.w60}`}
        style={{ height: "50%" }}
      >
        <div className={classes.circlesNotAnimated}>
          <div className={classes.circle}>
            <img src={brain} alt="" className={classes.brain} />
          </div>
          <div className={classes.circle}>
            <img src={heart} alt="" className={classes.heart} />
          </div>
        </div>
        <div className={classes.characterNotAnimated}>
          <img src={character} alt="" style={{ height: "80%" }} />
          <p>{userCtx.user.username}</p>
        </div>
      </div>
      <SessionButton text={"بعدی"} onClick={handleClick} />
    </>
  );
}

export default SessionFourP5;
