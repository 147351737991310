import { Routes, Route } from "react-router-dom";

import SessionsPage from "./pages/Sessions/Sessions";
import HomePage from "./pages/Home/Home";
import NotFound from "./pages/404/404";
import SessionPage from "./pages/Session/Session";
import SettingPage from "./pages/Setting/Setting";
import AuthPage from "./pages/Auth/Auth";
import ProtectedRouts from "./pages/ProtectedRoutes/ProtectedRoutes";

function App() {
  return (
    <>
      <Routes>
        <Route path="/auth/:name?" element={<AuthPage />} />
        <Route
          path="/"
          element={
            <ProtectedRouts>
              <HomePage />
            </ProtectedRouts>
          }
        />
        <Route
          path="/sessions"
          element={
            <ProtectedRouts>
              <SessionsPage />
            </ProtectedRouts>
          }
        />
        <Route
          path="/sessions/:name/:page"
          element={
            <ProtectedRouts>
              <SessionPage />
            </ProtectedRouts>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRouts>
              <SettingPage />
            </ProtectedRouts>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
