import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import SessionButton from "../../Session/SessionButton/SessionButton";
import { setSessionDone } from "../../../api/sessionsApi";

import classes from "./SessionEight.module.css";
import UserContext from "../../../store/user-context";

import character from "../../../assets/images/characterNormal.png";
import listening from "../../../assets/images/listening.png";
import voice from "../../../assets/voices/session8.mp3";
import HomeModal from "../../Home/modal/HomeModal";

function SessionEightP3(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [listenAgain, setListenAgain] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [hearedThings, setHearedThings] = useState("");
  const userCtx = useContext(UserContext);

  const closeModalhandler = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    setHearedThings(location.state.hearedThings);
  }, [location]);

  const handleClick = async () => {
    if (hearedThings !== "") {
      setSessionDone(
        props.name.substring(7, 8),
        hearedThings,
        () => navigate(`/sessions`),
        (val) => userCtx.getSessions(val)
      );
    } else {
      setModalOpen(true);
    }
  };
  return (
    <>
      <div className={classes.listening}>
        <img src={listening} alt="" style={{ width: listenAgain && "25%" }} />
      </div>
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "4%" }}
      >
        {listenAgain ? (
          <AudioPlayer
            autoPlay={false}
            onPlay={(e) => console.log(e)}
            onPause={(e) => console.log(e)}
            onEnded={(e) => console.log(e)}
            src={voice}
            style={{ height: "80%" }}
          />
        ) : (
          <button
            className={classes.button}
            onClick={() => setListenAgain(true)}
          >
            <svg
              width="15"
              height="17"
              viewBox="0 0 15 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5623 7.75398L1.2104 0.115369C0.941645 -0.0384564 0.657239 -0.0384564 0.387985 0.115369C0.11873 0.269441 0 0.55371 0 0.861608V16.1386C0 16.4462 0.11873 16.7307 0.387736 16.8846C0.522364 16.9616 0.648793 17 0.799069 17C0.949096 17 1.08745 16.9616 1.22208 16.8846L14.5683 9.24621C14.8373 9.09238 15 8.80787 15 8.50022C15 8.19232 14.8313 7.90805 14.5623 7.75398Z"
                fill="#58ABDC"
              />
            </svg>
            پخش دوباره
          </button>
        )}
      </div>

      <div className={classes.inputContiner}>
        <HomeModal onClose={closeModalhandler} isOpen={modalOpen}>
          <p className={classes.modalText}>هر چی شنیدی رو بنویس :)</p>
          <button className={classes.modalButton} onClick={closeModalhandler}>
            بستن
          </button>
        </HomeModal>
        <textarea
          name="items"
          id=""
          placeholder="حالا هر صدایی شنیدی رو اینجا بنویس."
          cols="35"
          rows="18"
          value={hearedThings}
          onChange={(e) => setHearedThings(e.target.value)}
        ></textarea>
      </div>
      <div className={classes.characterSmall}>
        <img src={character} alt="" />
      </div>
      <SessionButton text={"ذخیره"} onClick={handleClick} />
    </>
  );
}

export default SessionEightP3;
