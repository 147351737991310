import React from "react";
import { useNavigate } from "react-router-dom";
import Card from "../../Ui/Card";

import SessionButton from "../../Session/SessionButton/SessionButton";

function SessionFourP2(props) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/sessions/${props.name}/3`);
  };

  return (
    <>
      <div style={{ height: "90%" }}>
        <Card
          text={`تو این جلسه و چند جلسه بعد یاد می‌گیریم و تمرین می‌کنیم که میشه به جای تمرکز روی فکرها و احساسات
                ۱. اول اونها رو با فاصله ببینیم
                ۲. به جای گیر دادن به فکرها و احساساتمون ( اینکه چرا هستن و ...) روی چیزهای دیگه مثل تنفس تمرکز کنیم
                ۳. تحمل کردن رو یاد بگیریم.
                ۴. به جای گیر دادن به فکرها و احساساتمون ( اینکه چرا هستن و ...) روی چیزهای دیگه مثل محیط تمرکز کنیم.
                ۵. به جای توجه و گیر دادن به فکرها و احساساتمون، به جملات سالم درونمون توجه کنیم
                ۶. به جای گیر دادن به فکرهامون بگذاریم فقط بگذرند`}
          expand={true}
        />
      </div>
      <SessionButton text={"بعدی"} onClick={handleClick} />
    </>
  );
}

export default SessionFourP2;
