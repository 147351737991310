import React from "react";
import { useNavigate } from "react-router-dom";
import Card from "../../Ui/Card";
import SessionButton from "../../Session/SessionButton/SessionButton";

import classes from "./SessionTen.module.css";

import character from "../../../assets/images/characterNormal.png";
import room from "../../../assets/images/room.png";
import doors from "../../../assets/images/doors.png";

function SessionTen(props) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/sessions/${props.name}/2`);
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <div>
          <Card
            text={
              "حالا اینجا قرار هست یاد بگیری، فکرهایی که اذیتمون میکنن می‌تونن گذرا باشن. اینجا یک فکری که از جلسه ۳ داشتی و اذیتت میکنه رو بیار و وقتی از در میاد تو فقط نگاهش کن بعد بذار بره همین!"
            }
          />
        </div>
        <div className={classes.character}>
          <div style={{ position: "relative" }}>
            <img src={room} alt="" className={classes.room} />
            <img src={doors} alt="" className={classes.doors} />
          </div>

          <img src={character} alt="" className={classes.characterImg} />
        </div>
      </div>
      <SessionButton text={"بزن بریم!"} onClick={handleClick} />
    </>
  );
}

export default SessionTen;
